import axios from "axios";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://mt5-data-test.herokuapp.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res && res.data && res.data.result) {
      return res.data.result;
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/v1/crm/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
       originalConfig._retry = true;
        TokenService.updateLocalAccessToken(null);
        window.location  = '/login';
        // try {
        //   const rs = await instance.post("/auth/refreshtoken", {
        //     refreshToken: TokenService.getLocalRefreshToken(),
        //   });

        //   const { accessToken } = rs.data;
        //   TokenService.updateLocalAccessToken(accessToken);

        //   return instance(originalConfig);
        // } catch (_error) {
        //   return Promise.reject(_error);
        // }
      } 
    }

    return Promise.reject(err);
  }
);

export default instance;
