import defaultAxios from 'axios'

const axios = defaultAxios.create({
  baseURL: 'https://jsonplaceholder.typicode.com/',
  headers: {'Content-Type': 'application/json'}
});

// Get All login
export const getAlllogin = async () => {
  try {
    const login = await axios.get('https://mt5-data-test.herokuapp.com/api/v1/config/constants')

    return login.data
  } catch(err) {
    return console.error(err)
  }
}

// Create New Login
export const createNewLogin = async (title) => {
  try {
    const Login = await axios.post('login', {
      title
    })

    return Login.data
  } catch(err) {
    return console.error(err)
  }
}

// Delete existed Login
export const deleteExistedLogin = async (id) => {
  try {
    await axios.delete(`login/${id}`)
    
    return id
  } catch(err) {
     return console.error(err)
  }
}

export const getMyProfile = async () => {
  try {
    const profile = await axios.get(`profile/`)
    return profile;
  } catch(err) {
     return console.error(err)
  }
}