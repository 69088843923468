import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { FInput, FSelect, FCheckbox, FUserSelect } from 'CommonElements/Form';

import { Btn } from '../../../AbstractElements';

import { toast } from "react-toastify";
import { authService } from 'Services';


const EditprofileForm = () => {
    const userProfile = useSelector(
        (state) => (state && state.profile) || {}
      );
      const [loading, setLoading] = useState(false);

      const agentInfo = userProfile.User || {};
      const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        getValues
      } = useForm();
      const modalSubmit = (formValues) => {
        setLoading(true);
        authService.changePassword(formValues).then(res => {
            setLoading(false);
            toast.success('Password Updated, successfully');
        }).catch(err => {
            setLoading(false);
            toast.error(err?.response?.data?.message || 'Failed to change Password');

        })
      };

    return (<div>
         <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(modalSubmit)}
            >
          {/* <Card.Body> */}
            <Row>

                <Row>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'Current Password'
                            name = 'currentPassword'
                            register = {register}
                            placeholder= 'Enter Current Password'
                            validation={{required: true}}
                            type="password"
                        />
                    </Col>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'New Passowrd'
                            name = 'newPassword'
                            register = {register}
                            placeholder= 'Enter New Password'
                            validation={{required: true}}
                            type="password"

                        />
                    </Col>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'Confirm New Password'
                            name = 'confirmNewPassword'
                            register = {register}
                            placeholder= 'Confirm New Password'
                            validation={{required: true}}
                            type="password"

                        />
                    </Col>
                </Row>

            </Row>
            
          {/* </Card.Body> */}
          {/* <Card.Footer className="text-end"> */}
            <Btn disabled={loading} attrBtn={{ color: "primary" }}>
                {"Change Password"}
            </Btn>
          {/* </Card.Footer> */}
          </Form>
    </div>)
}
export default EditprofileForm;
