import React, { Fragment, useEffect, useState } from "react";
import { Label } from "reactstrap";
import DatePicker from "react-datepicker";
const DateTimeSelect = ({
  errors = {},
  label = "",
  name = "",
  register = () => {},
  type = "text",
  placeholder = "",
  value,
  setValue = () => {},
  validation = {},
  disabled,
  selectedValue,
  maxDate,
  minDate,
}) => {
  const [selectedValueDate, setSelectedValueDate] = useState(null);
  const handleChange = (e) => {
    setSelectedValueDate(e);
    setValue(name, e);
  };
  useEffect(() => {
    if (selectedValue !== undefined && selectedValue != null) {
      setValue(name, selectedValue);
    }
  }, [selectedValue]);
  return (
    <Fragment>
      <Label className="form-label" for="validationCustom01">
        {label}
      </Label>
      <DatePicker
        maxDate={maxDate}
        minDate={minDate}
        className="form-control digits"
        selected={selectedValueDate == null ? selectedValue : selectedValueDate}
        autoComplete="off"
        {...register(name, validation)}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        disabled={disabled}
      />
      <span>{errors[name] && `${label} is ${errors[name].type}`}</span>
      <div className="valid-feedback">Looks good!</div>
    </Fragment>
  );
};
export default DateTimeSelect;
