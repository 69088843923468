import { combineReducers } from 'redux'

// Reducers
import login from './login-reducer'
import config from './constants-reducer'

export default combineReducers({
    login,
    config,
  // Here you can registering another reducers.
})