import React, { Fragment, useEffect, useState } from 'react';
import { tradingAccountsService, accountTypeService } from 'Services';
import { Col, Row, Table } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import TablePagination from 'CommonElements/Table/TablePagination';

import AddAccount from './AddAccount';
import LinkAccount from './LinkAccount';
import OpenPositions from './OpenPositions';
import ClosePositions from './ClosePositions';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

const TradingAccounts = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const [openPositions, setOpenPositions] = useState({
    loading: false,
    positions: [],
  });
  const [closePositions, setClosePositions] = useState({
    loading: false,
    page: 1,
    size: 5,
    data: [],
  });
  const [selectedAccount, setSelectedAccount] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const [accountTypes, setAccountTypes] = useState([]);

  useEffect(()=>{
    if(activeTab === tabNumber) {
        accountTypeService.getAccountTypes().then(res => {
            if(res && res.data) {
                setAccountTypes(res.data);
            }
        }).catch(err => {

        })
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = async (page = 1) => {
    tradingAccountsService.getAccounts(customerId, false, page).then((res) => {
        setRec({
            data: [],
            ...res,
            loading: false,
        })
        }).catch(err =>{
        });
  }

  const loadPositions = async (item) => {
    setSelectedAccount(item.id);
  }
  useEffect(()=>{
    if(selectedAccount) {
        loadOpenPositions();
        loadClosePositions();
    }
  }, [selectedAccount])
  const loadOpenPositions = async () => {
    setOpenPositions({loading: true});
    try {
        const positions = await tradingAccountsService.getOpenPositions(selectedAccount);
        setOpenPositions({loading: false, positions});
    } catch (err) {

        setOpenPositions({loading: false});
    }
  };

  const loadClosePositions = async (page = 1) => {
    setClosePositions({loading: true});
    try {
        const positions = await tradingAccountsService.getClosePositions(selectedAccount, page);
        setClosePositions({loading: false, ...positions});
    } catch (err) {

        setClosePositions({loading: false});
    }
  };

  return (
    <Fragment>
        <Row>
            <Col md={12} className="d-flex flex-row-reverse">
                {getMenuPerm(permissions, 'accounts:create') === 1 &&
                    <AddAccount customerId={customerId} accountTypes={accountTypes} loadData={loadData} />
                }
                &nbsp; &nbsp;
                {getMenuPerm(permissions, 'accounts:create') === 1 &&
                    <LinkAccount customerId={customerId} accountTypes={accountTypes} loadData={loadData} />
                }
            </Col>
        </Row>
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{'#'}</th>
                        <th scope="col">{'Login'}</th>
                        <th scope="col">{'Type'}</th>
                        <th scope="col">{'Account Type'}</th>
                        <th scope="col">{'Balance'}</th>
                        <th scope="col">{'Payout'}</th>
                        <th scope="col">{'Credit'}</th>
                        <th scope="col">{'Margin Free'}</th>
                        <th scope="col">{'Equity'}</th>
                        <th scope="col">{'Leverage'}</th>
                        
                    </tr>
                </thead>
                {rec.loading && <TableLoader />}
                {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Accounts" />}
                
                <tbody>
                    {
                        rec.data && rec.data.map((item, id) =>
                            <tr key={id}>
                                <th scope="row">{item.id}</th>
                                <td >
                                    <strong style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>{loadPositions(item, id)}}>{item.login}</strong>
                                </td>
                                <td >{item.type}</td>
                                <td >{item['AccountType.title']}</td>
                                <td >{item.state && item.state.Balance}</td>
                                <td >{item?.transactions?.totalWithdrawals??0}</td>
                                <td >{item.state && item.state.Credit}</td>
                                <td >{item.state && item.state.MarginFree}</td>
                                <td >{item.state && item.state.Equity}</td>
                                <td >{item.state && item.state.MarginLeverage}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} />

        </div>
        <OpenPositions positions={openPositions.positions} />
        <ClosePositions positions={closePositions} paginationData={closePositions} loadData={loadClosePositions} />
    </Fragment>
  );
};
export default TradingAccounts;