import api from "./api";

export const getCustomerStats = () => {
  return api.get("/v1/crm/dashboard/customers");
};

export const getTransactionStats = () => {
  return api.get("/v1/crm/dashboard/transactions");
};

export const getRequestStats = () => {
  return api.get("/v1/crm/dashboard/requests");
};

export const getTransactionSummary = (days = 30) => {
  return api.get("/v1/crm/dashboard/transaction-sumary?days="+days);
};

export const getAccountsCount = () => api.get("/v1/crm/dashboard/accounts") ;
export const getIbAccountsCount = () => api.get("/v1/crm/dashboard/ib-accounts") ;
export const getDepositsCount = () => api.get("/v1/crm/dashboard/deposits") ;
export const getWithdrawalsCount = () => api.get("/v1/crm/dashboard/withdrawals") ;
export const getDepositsByDate = (dateFrom, dateTo) => api.get(`/v1/crm/dashboard/deposits-by-date?dateFrom=${dateFrom}&dateTo=${dateTo}`) ;
export const getDepositsFtd = () => api.get("/v1/crm/dashboard/deposits-first-time") ;
export const getDepositsBySales = () => api.get("/v1/crm/dashboard/deposits-by-sales") ;
