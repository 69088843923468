import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
} from 'reactstrap';
import { Leads } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import { leadService, activitiesService } from 'Services';
import { Link } from 'react-router-dom';
import TablePagination from 'CommonElements/Table/TablePagination';
import DataTable from 'react-data-table-component';
import AssignModal from './Modal/assignTo';
import LeadModal from './Modal/uploadLeads';
import { writeFile, utils } from 'xlsx';
import LeadModalButton from './LeadModalButton';
import { useSelector } from 'react-redux';
import { getMenuPerm } from 'utils';

const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  const [modal, setModal] = useState(false);
  const [leadBulkModal, setLeadBulkModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalMessage, setmodalMessage] = useState('');

  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'lead_sheet');
    return workbook;
  }
  const handleExport = () => {
    const workbook = exportToExcel(rec.data);
    writeFile(workbook, 'lead_list.xlsx');
  };
  const clearSelectedRow = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };
  const toggle = (e) => {
    if (e.target.textContent == 'Sales Assign') {
      setmodalMessage('Assign to');
    } else if (e.target.textContent == 'Delete Leads') {
      setmodalMessage('Delete Leads');
    }
    setModal(!modal);
  };
  const leadUploadToggle = (e) => {
    setLeadBulkModal(!leadBulkModal);
  };
  const tableColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      center: true,
    },
    {
      name: 'Name',
      sortable: false,
      center: true,
      cell: (item) => (
        <Link className='' to={`/leads/${item.id}`}>
          {`${item.firstName} ${item.lastName}`}
        </Link>
      ),
    },
    {
      name: 'Email',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isEmailView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.email}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'Phone',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isPhoneView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.phone}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'IB',
      selector: (row) => `${row['Parent.firstName']||''} ${row['Parent.lastName']||''}`,
      sortable: false,
      center: true,
    },
  ];
  useEffect(() => {
    loadData(1);
  }, []);
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  const emailPhoneViewClick = (e, index, customerId, type) => {
    e.preventDefault();
    var updatedRecord = rec;
    var isActive = false;
    var activityType = '';
    if (type === 'emailView') {
      activityType = 'email';
      isActive = updatedRecord.data[index].isEmailView === true ? false : true;
      updatedRecord.data[index].isEmailView = updatedRecord.data[
        index
      ].isEmailView = isActive;
    } else if (type === 'phoneView') {
      activityType = 'phone';
      isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
      updatedRecord.data[index].isPhoneView = updatedRecord.data[
        index
      ].isPhoneView = isActive;
    }
    if (isActive) {
      var postData = {
        type: activityType,
        customerId: customerId,
        note: activityType + ' View ',
      };
      postActivities(postData);
    }
    setRec({
      ...updatedRecord,
      loading: false,
    });
  };
  const postActivities = (payload) => {
    activitiesService
      .postActivity(payload)
      .then((res) => {})
      .catch((err) => {
      });
  };
  const loadData = (page = 1) => {
    leadService
      .getLeads({
        page,
      })
      .then((res) => {
        if (res.data.length > 0) {
          res.data = res.data.map((item) => ({
            ...item,
            isEmailView: false,
            isPhoneView: false,
          }));
        }
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md='2'>
                <H5>{Leads}</H5>
              </Col>
              <Col md='10'>
                <Row
                // className={
                //   selectedRows.length > 0 ? "float-start" : "float-end"
                // }
                >
                  {selectedRows.length > 0 ? (
                    <Col md='3'>
                      <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
                        Sales Assign
                      </Btn>
                      <AssignModal
                        modal={modal}
                        toggle={toggle}
                        setModal={setModal}
                        selectedSales={selectedRows}
                        checkBoxClear={clearSelectedRow}
                        loadLeadData={loadData}
                        titleMessage={modalMessage}
                      />
                    </Col>
                  ) : (
                    <Col md='3'></Col>
                  )}

                  {getMenuPerm(permissions, 'leads:delete') === 1 && selectedRows.length > 0 && (
                      <Col md='3'>
                      <Btn
                        attrBtn={{
                          color: 'secondary',
                          onClick: toggle,
                        }}
                      >
                        Delete Leads
                      </Btn>
                    </Col>
                    )}
                  <Col md='2'>
                    <Btn
                      attrBtn={{
                        color: 'btn btn-outline-primary ms-2',
                        onClick: leadUploadToggle,
                      }}
                    >
                      <i
                        className='fa fa-upload'
                        style={{ padding: '3px' }}
                      ></i>
                      <LeadModal
                        modal={leadBulkModal}
                        NewMessage='Upload Leads'
                        toggle={leadUploadToggle}
                        setModal={setLeadBulkModal}
                      />
                      Upload
                    </Btn>
                  </Col>
                   {getMenuPerm(permissions, 'leads:export') === 1 && rec.data && (
                      <Col md='3'>
                      <Btn
                        attrBtn={{
                          color: 'success',
                          onClick: handleExport,
                        }}
                      >
                        Export
                      </Btn>
                    </Col>
                    )}
                  <Col md={'2'}>
                    {getMenuPerm(permissions, 'leads:create') === 1 && (
                      <LeadModalButton btnText='Add' defaultVal='Godo' />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Container fluid={true} className='datatables'>
              <Row>
                <Col sm='12'>
                  <Card>
                    {rec.data ? (
                      <CardBody>
                        <DataTable
                          data={rec.data}
                          columns={tableColumns}
                          striped={true}
                          center={true}
                          selectableRows
                          persistTableHead
                          onSelectedRowsChange={handleRowSelected}
                          // contextActions={contextActions}
                          clearSelectedRows={toggleCleared}
                        />
                      </CardBody>
                    ) : null}
                  </Card>
                </Col>
              </Row>
            </Container>
            <TablePagination data={rec} loadData={loadData} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
