import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2';
import UploaderFile from '../../../Shared/FileUpload/Index';
import { documentsService } from 'Services';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from 'reactstrap';
import { Btn, UL, LI } from '../../../../AbstractElements';

const Documents = (props) => {
  const { customerId = '', loadData } = props;
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const [imageList, SetImageList] = useState([]);
  const { handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    if (imageList !== '') {
      setSubmitState({ loading: true });

      var formData = new FormData();
      formData.append('customerId', customerId);
      for (let index = 0; index < imageList.length; index++) {
        for (let i = 0; i < imageList[index].value.length; i++) {
          const fileData = imageList[index].value[i];
          formData.append(imageList[index].key, fileData);
        }
      }
      documentsService
        .uploadDocuments(formData)
        .then((res) => {
          setSubmitState({
            loading: false,
            status: 'Documents uploaded successfully',
          });
          SweetAlert.fire({
            title: 'Success',
            text: 'Documents uploaded sucessfully!',
            icon: 'success',
          });
          loadData();
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
            status: 'Error in uploading documents',
          });

          SweetAlert.fire({
            title: 'Failed',
            text: 'Error in uploading documents!',
            icon: 'error',
          });
        });
    }
  };
  const updateListFunction = (key, value) => {
    SetImageList([...imageList, { key, value }]);
  };
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <CardHeader className='p-3'>Upload Documents</CardHeader>
            <Form
              className='form theme-form needs-validation'
              noValidate=''
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardBody>
                <Row>
                  <FormGroup className='row'>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Proof of Id'
                        fileName='PROOF_OF_ID'
                        updateList={updateListFunction}
                      />
                    </Col>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Proof of Address'
                        fileName='PROOF_OF_ADDRESS'
                        updateList={updateListFunction}
                      />
                    </Col>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Additional Documents'
                        fileName='ADDITIONAL'
                        updateList={updateListFunction}
                      />
                    </Col>
                  </FormGroup>
                </Row>
                <Col md={12} className='d-flex flex-row-reverse'>
                  <Btn
                    disabled={submitState.loading}
                    attrBtn={{ color: 'primary' }}
                  >
                    {'Upload'}
                  </Btn>
                </Col>
              </CardBody>
            </Form>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='p-3'>
            <UL>
              <LI>
                Proof of ID max 2 files on every upload - Front & Back for ID -
                First & Second Page for Passport
              </LI>
              <LI>Proof of Address max 2 files</LI>
              <LI>
                Additional max 2 files on every upload, you can upload as many
                files as you want
              </LI>
            </UL>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Documents;
