import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const getClients = (params) => {
  return api.get('/v1/crm/customers/clients?' + queryString(params));
};
export const getClientDetail = (id) => {
  return api.get('/v1/crm/customers/client/' + id);
};
export const deleteBulkClients = (data) => {
  return api.delete('/v1/crm/customers/clients', { data }, {});
};
export const postClient = (params, token) => {
  return api.post('/v1/crm/customers/client', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const postBulkAssign = (params) => {
  return api.post('/v1/crm/customers/bulk-assign', params, {});
};
export const updateClient = (id, params) => {
  return api.patch('/v1/crm/customers/client/' + id, params);
};
export const getCustomersDropdown = (query = '', params = {}) => {
  params.query = query;
  return api.get('/v1/crm/customers/dropdown?' + queryString(params));
};
export const getCustomerSearchDropdown = (query) => {
  return api.get('/v1/crm/customers/dropdown?query=' + query);
};
