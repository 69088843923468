/* eslint-disable eqeqeq */
import React from 'react';
import { Previous, Next } from '../../Constant';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const TablePagination = ({ data = {}, loadData = ()=>{} }) =>{
    const {
        page = 1,
        // limit,
        // totalDocs,
        totalPages = 1,
        hasNextPage,
        hasPrevPage,
        nextPage,
        // pagingCounter,
        prevPage,
    } = data;
    if (totalPages <= 1) {
        return ('');
    }
    const fetchData = (pageSelected) => {
        loadData(pageSelected);
    }
    const addAllPage = () => {
        let tmp = [];
        const start = totalPages > 10 & page > 5 ? page-5: 0;
        const end = totalPages > 10 && page < (totalPages - 5)? parseInt(page)+(page > 5 ? 5: 10-page): totalPages;
        for(let i =start; i< end; i++) {
            tmp.push(i+1);
        }
        return tmp.map((pageNum, pageInd)=> {
            return <PaginationItem key={pageInd} active={pageNum == page}>
                <PaginationLink  onClick={()=>{fetchData(pageNum)}}>{pageNum}</PaginationLink>
            </PaginationItem>
        })
    }
    return (<nav className="m-b-30 m-t-30" aria-label="Page navigation example">
        <Pagination size='10' className="pagination justify-content-center pagination-primary" aria-label="Page navigation example">
            {hasPrevPage &&
                <PaginationItem><PaginationLink onClick={()=>{fetchData(prevPage)}}><span aria-hidden="true">«</span><span className="sr-only">{Previous}</span></PaginationLink></PaginationItem>
            }
            {addAllPage()}
            {hasNextPage &&
                <PaginationItem><PaginationLink onClick={()=>{fetchData(nextPage)}}><span aria-hidden="true">»</span><span className="sr-only">{Next}</span></PaginationLink></PaginationItem>
            }
        </Pagination>
    </nav>);
};

export default TablePagination;