import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { customerNotesService } from 'Services';
import CommonModal from 'Components/Common/Data/Ui-kits/Modals/common/modal';
import { FTextArea, FInput, FCheckbox, FDateTime } from 'CommonElements/Form';
import { Btn } from 'AbstractElements';

const AddBankAccount = ({ customerId, loadData, accounts = [] }, props) => {
  const { data = {} } = props;
  const [modal, setModal] = useState(false);
  const [isReminder, setIsReminder] = useState(false);
  const [currentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    // mode: 'onChange'
  });
  useEffect(()=>{
    setValue('isReminder', false);
  },[])

  const formValueChange = (name, value) => {
    setValue(name, value);
    if (name === 'isReminder') {
      setIsReminder(value);
    }
  };

  const modalSubmit = (formValues) => {
    if (formValues !== '') {
      formValues.clientId = customerId;
      const { transactionType, ...params } = formValues;
      setLoading(true);
      customerNotesService
        .postCustomerNotes(params)
        .then((res) => {
          setLoading(false);
          toggle();
          reset();
          toast.success('Note Added, successfully');
          loadData();
        })
        .catch((err) => {
          setLoading(false);
          toast.error('Failed to add Note');
        });
    } else {
      errors.showMessages();
      toast.error('Failed to add Note');
    }
  };
  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>
        {'Add Notes'}
      </Btn>

      <CommonModal
        isOpen={modal}
        title={'Add New Notes'}
        toggler={toggle}
        formSubmit={() => {}}
        hideFooter={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='5 mb-3'>
              <div style={{ marginLeft: '20px' }}>
                <FCheckbox
                  label='  Is Remainder'
                  name='isReminder'
                  setValue={formValueChange}
                  value={data.isReminder}
                />
              </div>
            </Col>
            {isReminder == true && (
              <Col md='7 mb-3'>
                <FDateTime
                  label='Remainder Time'
                  name='remainderTime'
                  setValue={formValueChange}
                  selectedValue={currentDate}
                  minDate={currentDate}
                />
              </Col>
            )}
            <Col md='12 mb-3'>
              <FTextArea
                errors={errors}
                label='Note'
                name='note'
                setValue={formValueChange}
                register={register}
                placeholder='Enter Note'
                validation={{ required: true }}
              />
            </Col>
            <Col md={12} className='d-flex flex-row-reverse'>
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>
                {'Add'}
              </Btn>
            </Col>
          </Row>

          {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default AddBankAccount;
