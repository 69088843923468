import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { accountTypeService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AccountAccountType = ({ loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)

  };
  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm({

  });
  

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        const params = {...formValues};
        accountTypeService.addAccountType(params).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Account Type added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Account Type');
        });
    } else {
        errors.showMessages();
    }
};
const [, setAccountType] = useState('');

const formValueChange = (name, value) => {
    setValue(name , value);
    setAccountType(value);
  }
  return (
    <Fragment>
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Add Account type'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Add New Account Type'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Account Type Title'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='12 mb-3' >

                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Account Type'
                                name = 'type'
                                register = {register}
                                placeholder= 'Account Type'
                                options = {[{name: 'IB', value: 'IB'},{name: 'LIVE', value: 'LIVE'},{name: 'DEMO', value: 'DEMO'}]}
                                validation={{required: true}}
                                value={''}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Account Group Path'
                                name = 'groupPath'
                                register = {register}
                                placeholder= 'Enter Group Path'
                                validation={{required: true}}
                            />
                        </Col>
                        {getValues().type === 'LIVE' &&
                            <Col md='12 mb-3' >
                                <FInput
                                    errors={errors}
                                    label = 'Account Swap Free Group Path'
                                    name = 'groupPathSwapFree'
                                    register = {register}
                                    placeholder= 'Enter Swap Free Group Path'
                                    validation={{required: true}}
                                    />
                            </Col>
                        }
                        
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Add'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AccountAccountType;
