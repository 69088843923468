import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import CustomContext from '../../_helper/Customizer';
import RightHeader from './RightHeader/index';
import { MenuSearchItems } from '../Sidebar/MenuSearch';
import { Form, Row } from 'reactstrap';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import { Loading } from '../../Constant';
import { ibService, leadService, clientService } from 'Services';
const Header = () => {
  const { toggleIcon } = useContext(CustomContext);

  const [mainmenu, setMainMenu] = useState(MenuSearchItems);
  const [iBList, setIBList] = useState(null);
  const [leadList, setLeadList] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [searchValue, setsearchValue] = useState('');
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);
  const { customizer } = useContext(CustomContext);

  const layout_type = customizer.settings.layout_type;
  // const layout_versions = customizer.color.mix_background_layout;

  // const layout_version = localStorage.getItem('layout_version') || (layout_versions && localStorage.setItem('layout_version', layout_versions));

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue('');
    }
  }, []);
  useEffect(() => {
    if (iBList != null && clientList != null && leadList != null) {
      if (mainmenu != null) {
        var updatedList = [...mainmenu];
        updatedList[0].Items[2] = {
          ...updatedList[0].Items[2],
          children: clientList,
        };
        updatedList[0].Items[1] = {
          ...updatedList[0].Items[1],
          children: leadList,
        };
        updatedList[0].Items[3] = {
          ...updatedList[0].Items[3],
          children: iBList,
        };
        setMainMenu(updatedList);
      }
    }
  }, [clientList, iBList, leadList]);

  const getCustomerBySearch = (query) => {
    var limit = 100;
    clientService
      .getCustomerSearchDropdown(query, {
        limit,
      })
      .then((res) => {
        if (res != null && res.length > 0) {
          var leadSearchData = [];
          var ibSearchData = [];
          var clientSearchData = [];
          res.map((x) => {
            if (x.isLead) {
              var leadData = {
                title:
                  '(Lead) - ' +
                  x.firstName +
                  ' ' +
                  x.lastName +
                  ' - ' +
                  x.phone +
                  ' - ' +
                  x.id +
                  ' - ' +
                  x.email +
                  ' - ' +
                  x.logins,
                path: `${process.env.PUBLIC_URL}/leads/` + x.id,
                type: 'link',
              };
              leadSearchData.push(leadData);
            } else if (x.isClient) {
              var clientData = {
                title:
                  '(Client) - ' +
                  x.firstName +
                  ' ' +
                  x.lastName +
                  ' - ' +
                  x.phone +
                  ' - ' +
                  x.id +
                  ' - ' +
                  x.email +
                  ' - ' +
                  x.logins,
                path: `${process.env.PUBLIC_URL}/clients/` + x.id,
                type: 'link',
              };
              clientSearchData.push(clientData);
            } else if (x.isIb) {
              var ibData = {
                title:
                  '(IB) - ' +
                  x.firstName +
                  ' ' +
                  x.lastName +
                  ' - ' +
                  x.phone +
                  ' - ' +
                  x.id +
                  ' - ' +
                  x.email +
                  ' - ' +
                  x.logins,
                path: `${process.env.PUBLIC_URL}/ibs/` + x.id,
                type: 'link',
              };
              ibSearchData.push(ibData);
            }
          });
          setClientList(clientSearchData);
          setLeadList(leadSearchData);
          setIBList(ibSearchData);
        }
        // setUsers(res.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);
  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    getCustomerBySearch(keyword);
    setsearchValue(keyword);
    mainmenu.map((menuItems) => {
      menuItems.Items.filter((mItems) => {
        if (
          mItems.title.toLowerCase().includes(keyword) &&
          mItems.type === 'link'
        ) {
          items.push(mItems);
        }
        if (!mItems.children) return false;
        mItems.children.filter((subItems) => {
          if (
            subItems.title.toLowerCase().includes(keyword) &&
            subItems.type === 'link'
          ) {
            subItems.icon = mItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((suSubItems) => {
            if (suSubItems.title.toLowerCase().includes(keyword)) {
              suSubItems.icon = mItems.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        checkSearchResultEmpty(items);
        setsearchValue(items);
        return mItems;
      });
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector('.empty-menu').classList.add('is-open');
    } else {
      setSearchResultEmpty(false);
      document.querySelector('.empty-menu').classList.remove('is-open');
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector('.Typeahead-menu').classList.add('is-open');
    document.body.classList.add(`${layout_type}`);
    // if (document.body.classList.value !== 'box-layout') {
    //   document.body.classList.add('offcanvas');
    // }
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue('');
    document.querySelector('.Typeahead-menu').classList.remove('is-open');
    document.body.classList.add(`${layout_type}`);
    document.body.classList.remove('offcanvas');
  };
  const removeGloabalSearch = () => {
    setsearchValue('');
    document.querySelector('.search-full').classList.remove('open');
    setSearchResult(false);
  };
  return (
    <Fragment>
      <div className={`page-header ${toggleIcon ? 'close_icon' : ''}`}>
        <Row className='header-wrapper m-0'>
          <Form className='form-inline search-full col' action='#' method='get'>
            <div className='form-group w-100'>
              <div className='Typeahead Typeahead--twitterUsers'>
                <div className='u-posRelative'>
                  <input
                    className='Typeahead-input form-control-plaintext w-100'
                    id='demo-input'
                    type='search'
                    placeholder='Search Cuba ..'
                    defaultValue={searchValue}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <div
                    className='spinner-border Typeahead-spinner'
                    role='status'
                  >
                    <span className='sr-only'>{Loading}</span>
                  </div>
                  <X className='close-search' onClick={removeGloabalSearch} />
                </div>
                <div
                  className='Typeahead-menu custom-scrollbar'
                  id='search-outer'
                >
                  {searchValue
                    ? searchValue.map((data, index) => {
                        return (
                          <div className='ProfileCard u-cf' key={index}>
                            <div className='ProfileCard-avatar'>
                              <data.icon />
                            </div>
                            <div className='ProfileCard-details'>
                              <div className='ProfileCard-realName'>
                                <Link
                                  to={data.path}
                                  className='realname'
                                  onClick={removeFix}
                                >
                                  {data.title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>
                <div className='Typeahead-menu empty-menu'>
                  <div className='tt-dataset tt-dataset-0'>
                    <div className='EmptyMessage'>
                      {'Opps!! There are no result found.'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
