import api from "./api";
export const getTickets = (params) => {
  return api.get("/v1/crm/tickets", params);
};
export const deleteTicket = (id) => {
  return api.delete("/v1/crm/tickets/" + id);
};
export const postTicket = (params) => {
  return api.post("/v1/crm/tickets/", params);
};
export const updateTicket = (params, id) => {
  return api.patch("/v1/crm/tickets/" + id, params);
};
