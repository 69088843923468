import api from './api';

export const getBankAccounts = (customerId)=> {
    return api.get('/v1/crm/bank-accounts?customerId='+customerId)
}

export const updateBankAccount = (id, data)=> {
    return api.patch('/v1/crm/bank-accounts/'+id, data)
}

export const deleteBankAccount = (id)=> {
    return api.delete('/v1/crm/bank-accounts/'+id)
}

export const addBankAccount = (data)=> {
    return api.post('/v1/crm/bank-accounts/', data)
}