import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import _ from "lodash";

import { roleService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FCheckbox } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditRole = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('title', data.title);
    setValue('permissions', data.permissions);
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        roleService.updateRole(data.id, formValues).then(res => {
            setLoading(false);
            toggle();
            toast.success('Role updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Role');
        });
    } else {
        errors.showMessages();
    }
};
const getUpperCase = (str) => {
   return _.startCase(str);

}
  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit Role'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            {/* {JSON.stringify(data.permissions)} */}
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Role Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Role Title'
                                validation={{required: true}}
                            />
                        </Col>
                        {Object.keys(data.permissions).map((permission, pInd) =>
                            <Col md='12 mb-1' key={pInd}>
                                <label>{getUpperCase(permission)}</label>
                                <Row>
                                    {Object.keys(data.permissions[permission]).map((key, kInd) =>
                                        <Col md='2 mb-1' key={kInd}>
                                            <FCheckbox
                                                label = {getUpperCase(key)}
                                                name = {`permissions[${permission}][${key}]`}
                                                setValue={setValue}
                                                value={data.permissions[permission][key]}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        )}
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditRole;
