import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, CardHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FSelect, FCheckbox, FUserSelect } from 'CommonElements/Form';
import { clientService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const BasicInformation = (props) => {
  const {
    customerId = '',
    data = {},
  } = props;

  const [submitState, setSubmitState] = useState({
    loading: false,
  })
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
  useEffect(()=>{
    const formFields = getValues();
    Object.keys(formFields).forEach(key => {
      if(data[key]) {
        setValue(key, data[key])
      }
    })
  }, [])

  const onSubmit = data => {
    if (data !== '') {
        setSubmitState({loading: true});
        clientService.updateClient(customerId, data).then(res => {
            setSubmitState({
                loading: false,
                status: 'Profile updated sucessfully'
            })
            SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'error' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'info' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'warning' });

        }).catch(err => {
            setSubmitState({
                loading: false,
                status: 'Error in updating Profile'
            })
            SweetAlert.fire({ title: 'Failed', text: 'Error in updating Profile!', icon: 'error' });
        });
        // alert('You submitted the form and stuff!');
    } else {
        errors.showMessages();
    }
};

  return (
    <Fragment>
        <Card>
            <CardHeader className='p-3'>
                Basic Information Coportate
            </CardHeader>
            <CardBody>
                <Form  className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Select Title'
                                options = {[{name: 'Mr', value: 'Mr'},{name: 'Ms', value: 'Ms'}]}
                                validation={{required: true}}
                                value={data.title}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'First Name'
                                name = 'firstName'
                                register = {register}
                                placeholder= 'Enter First Name'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Last Name'
                                name = 'lastName'
                                register = {register}
                                placeholder= 'Enter Last Name'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email'
                                name = 'email'
                                register = {register}
                                placeholder= 'Enter Email Address'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'City'
                                name = 'city'
                                register = {register}
                                placeholder= 'Enter City'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'State'
                                name = 'state'
                                register = {register}
                                placeholder= 'Enter State'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Country of Residence'
                                name = 'country'
                                register = {register}
                                placeholder= 'Enter Country of Residence'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Nationality'
                                name = 'nationality'
                                register = {register}
                                placeholder= 'Enter Nationality'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Phone'
                                name = 'phone'
                                register = {register}
                                placeholder= 'Enter Phone Number'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Mobile'
                                name = 'mobile'
                                register = {register}
                                placeholder= 'Enter Mobile'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Date of Birth'
                                name = 'dob'
                                register = {register}
                                placeholder= 'Enter Date of Birth'
                                type="date"
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Call Status'
                                name = 'callStatus'
                                register = {register}
                                placeholder= 'Enter Call Status'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Select Gender'
                                name = 'gender'
                                register = {register}
                                placeholder= 'Select Gender'
                                options = {[{name: 'Male', value: 'male'}, {name: 'Female', value: 'female'}]}
                                validation={{required: true}}
                                value={data.gender}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Address'
                                name = 'address'
                                register = {register}
                                placeholder= 'Enter Address'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Address 2'
                                name = 'address2'
                                register = {register}
                                placeholder= 'Enter Address 2'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Zip Code'
                                name = 'zipCode'
                                register = {register}
                                placeholder= 'Enter Zip Code'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Source'
                                name = 'source'
                                register = {register}
                                placeholder= 'Enter Source'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Select Language'
                                name = 'language'
                                register = {register}
                                placeholder= 'Select Language'
                                options = {[{name: 'English', value: 'EN'}]}
                                value={data.language}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is US Citizen?'
                                name = 'fatca'
                                setValue={setValue}
                                value={data.fatca}

                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is Politically Exposed?'
                                name = 'politicallyExposed'
                                setValue={setValue}
                                value={data.politicallyExposed}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Worked in Crypto?'
                                name = 'workedInCrypto'
                                setValue={setValue}
                                value={data.workedInCrypto}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Tax ID, NTN'
                                name = 'taxIdentificationNumber'
                                register = {register}
                                placeholder= 'Enter Tax ID'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FUserSelect
                                control={control}
                                errors={errors}
                                label = 'Select Agent'
                                name = 'agentId'
                                validation={{required: true}}
                                setValue={setValue}
                                value={data.agentId}
                                defaultOptions={data.Agent ? [data.Agent]:[]}
                                
                                // isClient={true}
                            />
                        </Col>
                    </Row>
                    

                    {getMenuPerm(permissions, 'clients:update') === 1 &&
                        <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                    }
                </Form>
            </CardBody>
        </Card>
    </Fragment>
  );
};
export default BasicInformation;
