import { spawn } from 'redux-saga/effects'

// Sagas
import loginSaga from './login-saga'
import constantsSaga from './constants-saga'

// Export the root saga
export default function* rootSaga() {
  yield spawn(loginSaga)
  yield spawn(constantsSaga)
}