import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { clientService } from 'Services';
import Spinner from 'CommonElements/Spinner';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ClientDetailsHeader from './ClientDetailHeader';
import ClientDetailFooter from './ClientDetailFooter';
import { useParams } from 'react-router-dom';
import ClientProfile from './ClientProfile/ClientProfile';
import BankAccounts from './BankAccounts/BankAccounts';
import Documents from './Documents/Documents';
import Accounts from './Accounts/Accounts';
import Transactions from './Transactions/Transactions';
import Notes from './Notes/Index';
import Logs from './Logs/Logs';
import Agreements from './Agreements/Agreements';
import Statement from './Statement/Statement';
import IbClients from './IbClients/IbClients';


const ContactFirebase = (props) => {
  const [rec, setRec] = useState({});
  const pathParams = useParams();
  const customerId = pathParams.id;

  useEffect(() => {
    if (pathParams.id) {
      setRec({
        loading: true,
        data: [],
      });
      clientService
        .getClientDetail(pathParams.id)
        .then((res) => {
          setRec({
            ...res,
            loading: false,
          });
          setActiveTab(0);
        })
        .catch((err) => {
        });
    }
  }, []);

  // code for tabs start
  const [activeTab, setActiveTab] = useState();


  const getTabsArr = () => {
    const tabsArr = [
      {
        component: ClientProfile,
        title: 'Profile',
        icon: 'icofont-man-in-glasses',
      },
      { component: Documents, title: 'Documents', icon: 'icofont-contacts' },
      {
        component: BankAccounts,
        title: 'Banks & Wallets',
        icon: 'icofont-ui-home',
      },
      { component: Accounts, title: 'Accounts', icon: 'icofont-contacts' },
      { component: Transactions, title: 'Transactions', icon: 'icofont-money' },
      { component: Logs, title: 'Logs', icon: 'icofont-sand-clock' },
      { component: Notes, title: 'Notes', icon: 'icofont-newspaper' },
      // { component: Agreements, title: 'Commission', icon: 'icofont-newspaper' },
      // { component: Statement, title: 'Statement', icon: 'icofont-newspaper' },
      // { component: IbClients, title: 'Clients', icon: 'icofont-newspaper' },
      
    ];
    if(rec?.isIb) {
      tabsArr.push({ component: Agreements, title: 'Commission', icon: 'icofont-newspaper' });
    }
    if(rec?.isIb && rec?.ibStatus === 'approved') {
      tabsArr.push({ component: Statement, title: 'Statement', icon: 'icofont-newspaper' });
      tabsArr.push({ component: IbClients, title: 'Clients', icon: 'icofont-newspaper' });
    }
    return tabsArr;
  }

  return (
    <Fragment>
      <Container fluid={true}>
        {rec.loading && <Spinner />}
        {!rec.loading && (
          <Row>
            <Col sm={'12'}>
              <div className='user-profile mt-4'>
                <ClientDetailsHeader customerId={customerId} data={rec} />
              </div>
              <Nav tabs className='mb-4'>
                {getTabsArr().map((tab, index) => (
                  <NavItem key={index}>
                    <NavLink
                      href='#'
                      className={activeTab === index ? 'active' : ''}
                      onClick={() => setActiveTab(index)}
                    >
                      <i className={`icofont ${tab.icon}`}></i>
                      {tab.title}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {getTabsArr().map((tab, index) => (
                  <TabPane key={index} className='fade show' tabId={index}>
                    <tab.component
                      isActive={activeTab === index}
                      customerId={customerId}
                      data={rec}
                      activeTab={activeTab}
                      tabNumber={index}
                    />
                  </TabPane>
                ))}
              </TabContent>
              <div className='user-profile mt-4'>
                <ClientDetailFooter customerId={customerId} data={rec} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};
export default ContactFirebase;
