import React, { Fragment, useEffect, useState } from "react";
import { Col, Table, Container } from "reactstrap";
import { roleService } from "Services";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Row } from "react-bootstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { FSwitch } from 'CommonElements/Form';
import { toast } from "react-toastify";

import AddRole from "./AddNewRole";
import DeleteRole from "./DeleteRole";
import EditRole from "./EditRole";
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    roleService
      .getRoles({
        page
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        setRec({
          loading: false,
          data: [],
        });
      });
  };
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const changeStatus = (item, index) => {
    roleService.updateRole(item.id, {
      isActive: !item.isActive
    }).then(res => {
      rec.data[index].isActive = !item.isActive;
      toast.success('Role updated, successfully');
    }).catch(err => {
      toast.error('Failed to update Role');
    })
  }

  return (
    <Fragment>
      <Breadcrumbs title="Roles" mainTitle="" />
      <Container fluid={true}>
        <Row>
          <Col md={12} className="d-flex flex-row-reverse">
          {getMenuPerm(permissions, 'roles:create') === 1 &&
            <AddRole loadData={loadData} />
          }
          </Col>
        </Row>
        <div className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th scope="col">{"#"}</th>
                <th scope="col">{"Title"}</th>
                <th scope="col">{"Status"}</th>
                <th scope="col">{"Actions"}</th>
              </tr>
            </thead>
            {rec.loading && <TableLoader />}
            {!rec.loading && rec.data && rec.data.length === 0 && (
              <TableNodata title="Roles" />
            )}
            <tbody>
              {rec.data &&
                rec.data.map((item, id) => (
                  <tr key={id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.title}</td>
                    <td>
                      
                    {getMenuPerm(permissions, 'roles:update') === 1 &&
                      <FSwitch onChange={()=>{changeStatus(item, id)}} value={item.isActive} oncha/>
                    }
                    </td>
                    <td>
                    {getMenuPerm(permissions, 'roles:update') === 1 &&
                      <EditRole data={item} loadData={loadData} />
                    }
                    {getMenuPerm(permissions, 'roles:delete') === 1 &&
                      <DeleteRole data={item} loadData={loadData} />
                    }
                    </td>{" "}
                  </tr>
                ))}
            </tbody>
          </Table>
          <TablePagination data={rec} loadData={loadData} />
        </div>
      </Container>
    </Fragment>
  );
};

export default HoverableRowsClass;
