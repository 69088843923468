import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { bannersService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditBanner = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('title', data.title);
    setValue('description', data.description);
    
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        
        var formData = new FormData();
        formData.append("title", formValues.title);
        formData.append("description", formValues.description);
        if(formValues['fileUrl'] && formValues['fileUrl'][0]) {
            formData.append("fileUrl", formValues['fileUrl'][0]);
        }


        bannersService.updateBanner(data.id, formData).then(res => {
            setLoading(false);
            toggle();
            toast.success('Banner updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Banner');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files);
}

  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit Banner'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            {/* {JSON.stringify(data.permissions)} */}
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                     <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Banner Title'
                                validation={{required: true}}
                            />
                        </Col>
                        
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Description'
                                name = 'description'
                                register = {register}
                                placeholder= 'Enter Description'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label">{'Image'}</Label>
                                <Col sm="9">
                                    <input onChange={handleChange} name="fileUrl" className="form-control" type="file" />
                                </Col>
                            </FormGroup>
                        </Col>
                        
                       
                        
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditBanner;
