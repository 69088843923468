import React, { Fragment } from 'react';
import { Label, Input, Media } from 'reactstrap'

const FormInput = ({
    value=false,
    onChange = () => {}
}) => {
  return (
    <Fragment>
        <Media body className={'switch-thin switch-outline'}>
            <Label className="switch">
                <Input defaultChecked={value} onChange={onChange} type="checkbox" /><span className={'switch-state'} ></span>
            </Label>
        </Media>
    </Fragment>
  );
};
export default FormInput;