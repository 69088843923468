import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const getCustomerNotes = (params) => {
  return api.get('/v1/crm/customer-notes/?' + queryString(params));
};

export const postCustomerNotes = (params) => {
  return api.post('/v1/crm/customer-notes', params);
};
