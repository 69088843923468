import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import BasicInfoLead from './BasicInfoLead';
import BasicInfoClientInd from './BasicInfoClientInd';
import BasicInfoClientCorp from './BasicInfoClientCorp';
import BasicInfoIb from './BasicInfoIb';


// import Experience from './Experience';

const ClientProfile = (props) => {
  const {
    isActive = false,
    customerId = '',
    data = {},
    activeTab, tabNumber
  } = props;


  useEffect(()=>{
    if(activeTab === tabNumber && isActive) {
    }
  }, [activeTab, isActive, tabNumber])


  return (
    <Fragment>
        <Row>
          <Col sm={12}>

            {data.isLead && <BasicInfoLead customerId={customerId} data={data}/>}
            {!data.isLead && !data.isIb && data.isCorporate && <BasicInfoClientCorp customerId={customerId} data={data}/>}            
            {!data.isLead && !data.isIb && !data.isCorporate && <BasicInfoClientInd customerId={customerId} data={data}/>}

            {!data.isLead && data.isIb && <BasicInfoIb customerId={customerId} data={data}/>}

          </Col>
          {/* <Col sm={4}>
            <Experience customerId={customerId} data={data}/>
          </Col> */}
        </Row>
        

    </Fragment>
  );
};
export default ClientProfile;


// fatca
// politicallyExposed
// workedInCrypto
// taxIdentificationNumber