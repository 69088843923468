import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../../AbstractElements";
// import TodoCheckbox from "./TodoCheckbox";
import TodoList from "./TodoList";

const TodoContain = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <H5>Tickets</H5>
              </CardHeader>
              <CardBody style={{ padding: "20px" }}>
                <div className="todo">
                  <div className="todo-list-wrapper">
                    <div className="todo-list-container">
                      {/* <TodoCheckbox /> */}
                      <TodoList />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TodoContain;
