import React, { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Table,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { ibService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddAgreement from './AddAgreement';
import DeleteAgreement from './DeletAgreement';
import { getMenuPerm } from 'utils';
import MakeIb from './MakeIb';
import RejectIb from './RejectIb';

import ViewAgreement from 'Components/Common/Component/ViewAgreement';
import GroupSetting from './GroupSetting';

const Agreements = (props) => {
  const { customerId = '', activeTab, tabNumber, data = {} } = props;
  const [rec, setRec] = useState({});
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  useEffect(() => {
    if (activeTab === tabNumber) {
      loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    ibService
      .getAgreements(customerId)
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className='p-3'>
          <Row>
            <Col md={4} className='d-flex'>
              Commission Structure
            </Col>
            <Col md={8} className='d-flex'>
              <div
                style={{ display: 'block', textAlign: 'right', width: '100%' }}
              >
                {data?.ibStatus === null && (
                  <>
                    {getMenuPerm(permissions, 'ibs:create') === 1 && (
                      <React.Fragment>
                        <MakeIb customerId={customerId} loadData={loadData} />
                        <RejectIb customerId={customerId} loadData={loadData} />
                      </React.Fragment>
                    )}
                  </>
                )}

                {data?.ibStatus === 'approved' &&
                  !rec.loading &&
                  rec?.data?.length === 0 && (
                    <>
                      {getMenuPerm(permissions, 'ibs:create') === 1 && (
                        <AddAgreement
                          customerId={customerId}
                          loadData={loadData}
                        />
                      )}
                    </>
                  )}
                  {!rec.loading &&
                    rec?.data?.length > 0 && (
                      <>
                        {getMenuPerm(permissions, 'ibs:create') === 1 && (
                          <DeleteAgreement
                            customerId={customerId}
                            loadData={loadData}
                            agreementId={rec?.data[0]?.id}
                          />
                        )}
                      </>
                  )}
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && (
            <TableNodata title='Commission Structure' />
          )}
          {!rec.loading && rec.data && rec.data.length > 0 && (
            <Row>
              <Col sm={6}>
                <ViewAgreement agreement={rec.data[0]} />
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={12}>
                    <Card>
                      <CardHeader className='p-3'>Dedicated Links</CardHeader>
                      <CardBody>
                        <p className='mb-0 ml-2'>
                          Register Demo &nbsp;{' '}
                          <a className='pl-3' href='/register-demo'>
                            Demo
                          </a>
                        </p>
                        <p className='mb-0 ml-2'>
                          Register Live &nbsp;&nbsp;&nbsp;{' '}
                          <a className='pl-3' href='/register-live'>
                            Live
                          </a>
                        </p>
                        <p className='mb-0 ml-2'>
                          Register Sub Ib{' '}
                          <a className='pl-3' href='/register-ib'>
                            Sub IB
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                {getMenuPerm(permissions, 'ibs:update') === 1 && (
                  <GroupSetting
                    customerId={customerId}
                    activeTab={activeTab}
                    tabNumber={tabNumber}
                  />
                )}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default Agreements;
