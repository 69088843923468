import React from 'react';
import { CardBody, Col } from 'reactstrap';
import ApexCharts from 'react-apexcharts';
import configDB from '../../../../../Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

const Charts = ({data = []}) =>{
  const Currentlysale = {
    series: [{
      name: 'Deposits',
      data: data.map(obj => obj.deposits),
    }, {
      name: 'Withdrawals',
      data: data.map(obj => obj.withdrawals),
    }],
    options: {
      chart: {
        height: 240,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'category',
        low: 0,
        offsetX: 0,
        offsetY: 0,
        show: false,
        categories: data.map(obj => obj.label),
        labels: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisBorder: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 3,
        colors: '#ffffff',
        strokeColors: [primary, secondary],
        hover: {
          size: 6,
        }
      },
      yaxis: {
        low: 0,
        offsetX: 0,
        offsetY: 0,
        show: false,
        labels: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisBorder: {
          low: 0,
          offsetX: 0,
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          bottom: -15,
          top: -40
        }
      },
      colors: [primary, secondary],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100]
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'MM'
        },
      },
    }
  
  };

    return(
      <Col xl="12">
        <CardBody className="p-0">
          <div className="current-sale-container">
            <ApexCharts id="chart-currently" options={Currentlysale.options} series={Currentlysale.series} type='area' height={240} />
          </div>
        </CardBody>
      </Col>
    );
};

export default Charts;