const arr = [
  { name: 'Deposit (CBD)', value: 'Deposit (CBD)' },
  { name: 'Deposit (USD AfrAsia)', value: 'Deposit (USD AfrAsia)' },
  { name: 'Deposit (AED AfrAsia)', value: 'Deposit (AED AfrAsia)' },
  {
    name: 'Deposit (Rebate Transfer to TP)',
    value: 'Deposit (Rebate Transfer to TP)',
  },
  { name: 'Deposit (USD In Person)', value: 'Deposit (USD In Person)' },
  { name: 'Deposit (USDT Crypto)', value: 'Deposit (USDT Crypto)' },
  { name: 'Deposit (USDT - IR Crypto)', value: 'Deposit (USDT - IR Crypto)' },
  { name: 'Deposit (Credit In)', value: 'Deposit (Credit In)' },
  { name: 'Deposit (others)', value: 'Deposit (others)' },
  { name: 'AED Online Deposit', value: 'AED Online Deposit' },
  { name: 'USD Online Deposit', value: 'USD Online Deposit' },
  { name: 'INR Online Deposit', value: 'INR Online Deposit' },
  { name: 'Stopout Adjustment', value: 'Stopout Adjustment' },
  { name: 'Withdrawal (CBD)', value: 'Withdrawal (CBD)' },
  { name: 'Withdrawal (USD AfrAsia)', value: 'Withdrawal (USD AfrAsia)' },
  { name: 'Withdrawal (EUR AfrAsia)', value: 'Withdrawal (EUR AfrAsia)' },
  { name: 'Withdrawal (AED AfrAsia)', value: 'Withdrawal (AED AfrAsia)' },
  { name: 'Withdrawal (USD In Person)', value: 'Withdrawal (USD In Person)' },
  { name: 'Withdrawal (USDT Crypto)', value: 'Withdrawal (USDT Crypto)' },
  {
    name: 'Withdrawal (USDT - IR Crypto)',
    value: 'Withdrawal (USDT - IR Crypto)',
  },
  { name: 'Withdrawal (Credit out)', value: 'Withdrawal (Credit out)' },
  { name: 'Withdrawal (others)', value: 'Withdrawal (others)' },
  {
    name: 'Deposit (Adjustment - Stop out)',
    value: 'Deposit (Adjustment - Stop out)',
  },
  { name: 'Withdrawal (Adjustment)', value: 'Withdrawal (Adjustment)' },
  { name: 'Deposit (Adjustment)', value: 'Deposit (Adjustment)' },
  {
    name: 'Withdrawal (Zulutrade Profit share)',
    value: 'Withdrawal (Zulutrade Profit share)',
  },
  {
    name: 'Withdrawal (FIX API Account)',
    value: 'Withdrawal (FIX API Account)',
  },
  { name: 'AED Online Withdrawal', value: 'AED Online Withdrawal' },
  { name: 'Deposit (ICICI)', value: 'Deposit (ICICI)' },
  { name: 'Withdrawal (ICICI)', value: 'Withdrawal (ICICI)' },
  { name: 'Deposit (IDBI)', value: 'Deposit (IDBI)' },
  { name: 'Withdrawal (IDBI)', value: 'Withdrawal (IDBI)' },
  { name: 'Deposit (CBD Mauritius)', value: 'Deposit (CBD Mauritius)' },
  { name: 'Withdrawal (CBD Mauritius)', value: 'Withdrawal (CBD Mauritius)' },
  { name: 'Deposit India Bank Account', value: 'Deposit India Bank Account' },
  {
    name: 'Withdrawal India Bank Account',
    value: 'Withdrawal India Bank Account',
  },
  {
    name: 'Deposit Pakistan Bank Account',
    value: 'Deposit Pakistan Bank Account',
  },
  {
    name: 'Withdrawal Pakistan Bank Account',
    value: 'Withdrawal Pakistan Bank Account',
  },
  { name: 'Deposit Equals Money', value: 'Deposit Equals Money' },
  { name: 'Withdrawal Equals Money', value: 'Withdrawal Equals Money' },
  { name: 'Deposit - Perfect Money', value: 'Deposit - Perfect Money' },
  { name: 'Withdrawal - Perfect Money', value: 'Withdrawal - Perfect Money' },
  { name: 'Deposit (YES Bank)', value: 'Deposit (YES Bank)' },
  { name: 'Withdrawal (YES Bank)', value: 'Withdrawal (YES Bank)' },
  { name: 'Deposit (Lebanon Clients)', value: 'Deposit (Lebanon Clients)' },
  {
    name: 'Withdrawal (Lebanon Clients)',
    value: 'Withdrawal (Lebanon Clients)',
  },
];
export default arr;
