import React, { useContext, useState } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import logo from "./godo_logo";
import CustomizerContext from "../../_helper/Customizer";

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout } = useContext(CustomizerContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  return (
    <div className="logo-wrapper">
      {layout1 !== "compact-wrapper dark-sidebar" && mixLayout ? (
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          {/* <Image attrImage={{ className: 'img-fluid d-inline', src: `${CubaIcon}`, alt: '' }} /> */}
          {logo}
        </Link>
      ) : (
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          {logo}
          {/* <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../assets/images/logo/godo_image.png')}`, alt: '' }} /> */}
        </Link>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
    </div>
  );
};

export default SidebarLogo;
