import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getTransactions = (params) => {
  return api.get("/v1/crm/transactions?" + queryString(params));
};

export const postDeposit = (params) => {
  return api.post("/v1/crm/transactions/deposit", params);
};
export const postTransaction = (type, params) => {
  return api.post("/v1/crm/transactions/" + type, params);
};
export const postWithdrawals = (params) => {
  return api.post("/v1/crm/transactions/withdrawal", params);
};

export const postInterTransfer = (params) => {
  return api.post("/v1/crm/transactions/internal-transfer", params);
};
export const postTransactionApproved = (params, id) => {
  return api.post("/v1/crm/transactions/" + id + "/approve", params);
};
export const postTransactionReject = (params, id) => {
  return api.post("/v1/crm/transactions/" + id + "/reject", params);
};
