import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, Table, Container } from "reactstrap";
import { SystemEmails } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { systemEmail } from "Services";
import { Breadcrumbs } from '../../../AbstractElements';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import DeleteSystemEmail from './DeleteSystemEmail';
import AddSystemEmail from './AddSystemEmail';
import EditSystemEmail from './EditSystemEmail';
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    systemEmail.getEmail({page}).then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      }).catch((err) => {
        setRec({
          loading: false,
          data: []
        });
      });
  }
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  

  return (
    <Fragment>
      <Breadcrumbs title="System Emails" mainTitle='' />
      <Container fluid={true}>
          <Row>
            <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    {" "}
                    <H5>{SystemEmails}</H5>
                  </Col>
                  <Col md="4">
                    <Row className="float-end">
                      <Col md={12} className="d-flex flex-row-reverse">
                      {getMenuPerm(permissions, 'systemEmails:create') === 1 &&
                        <AddSystemEmail loadData={loadData} />
                      }

                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">{"Title"}</th>
                      <th scope="col">{"Subject"}</th>
                      {/* <th scope="col">{"Fields"}</th> */}
                      <th scope="col">{"Action"}</th>
                      <th scope="col">{"Actions"}</th>

                    </tr>
                  </thead>
                  {rec.loading && <TableLoader />}
                  {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="System Emails" />}
                    
                  <tbody>
                    {rec.data &&
                      rec.data.map((item, id) => (
                        <tr key={id}>
                          <th scope="row">{item.title}</th>
                          <td>{item.subjectEn}</td>
                          {/* <td>{item.fields.join(',')}</td> */}
                          <td>{item.action}</td>
                          <td>
                          {getMenuPerm(permissions, 'systemEmails:update') === 1 &&
                            <EditSystemEmail data={item} loadData={loadData}  />
                          }
                          {getMenuPerm(permissions, 'systemEmails:delete') === 1 &&
                            <DeleteSystemEmail data={item} loadData={loadData}  />
                          }

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
            <TablePagination data={rec} loadData={loadData} />
              </div>
            </Card>
          </Col>
          </Row>
      </Container>
    </Fragment>
 
  );
};

export default HoverableRowsClass;
