import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table, Card, CardHeader, Form, Badge } from 'reactstrap';
import { ibService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
// import { useSelector } from "react-redux";
import TablePagination from 'CommonElements/Table/TablePagination';
import { useForm } from 'react-hook-form';
import { FInput } from 'CommonElements/Form';
import { Btn, H4 } from "AbstractElements";
import moment from 'moment';


const IbStatement = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [dealWiseData, setDealWiseData] = useState(null);
  const [statementDateRange, setStatementDateRange] = useState({});
//   const permissions = useSelector(
//     (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
//   );
  const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();

  useEffect(()=>{
    if(activeTab === tabNumber) {
        const dateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');
        const dateTo = moment().add(1, 'day').format('YYYY-MM-DD');
        setValue('dateFrom', dateFrom)
        setValue('dateTo', dateTo)
        setStatementDateRange({
            dateFrom,
            dateTo,
        })
        // loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = (page = 1) => {
    setRec({
        loading: true,
    })
    setDealWiseData(null);
    ibService.getStatement({ibId: customerId, page, ...statementDateRange}).then((res) => {
        setRec({
            ...res,
            loading: false,
        })
        }).catch(err =>{
            setRec({
                loading: false,
            })
        });
  }

  const loadDealWiseSatement = async (page = 1) => {
    setDealWiseData({
        loading: true,
    });
    ibService.getStatementDealWise({
        ibId: customerId,
        page,
        clientLogin: selectedClient?.clientLogin,
        ...statementDateRange,
    }).then((res) => {
        setDealWiseData({
            ...res,
            loading: false,
        });
    }).catch(err =>{
        setDealWiseData({
            loading: false,
        });
    });
  }

  useEffect(()=>{
    if(selectedClient) {
        loadDealWiseSatement();
    }
  }, [selectedClient]);

  const DealWiseTable = () => {
    return(<>
        {dealWiseData !== null && <Card>
            <CardHeader className='p-3'>
            <Row>
                <Col md={12} className="d-flex">
                    <H4 attrH4={{className: "m-1"}}><Badge>Login - ({selectedClient?.clientLogin}) </Badge></H4>
                    <H4 attrH4={{className: "m-1"}}><Badge>Equity {dealWiseData?.clientEquity?.Equity ?? '---'} </Badge></H4>
                    <H4 attrH4={{className: "m-1"}}><Badge>Balance {dealWiseData?.clientEquity?.Balance ?? '---'} </Badge></H4>
                    <H4 attrH4={{className: "m-1"}}><Badge>MarginFree {dealWiseData?.clientEquity?.MarginFree ?? '---'} </Badge></H4>
                    <H4 attrH4={{className: "m-1"}}><Badge>Credit {dealWiseData?.clientEquity?.Credit ?? '---'}</Badge></H4>
                </Col>
            </Row>
            </CardHeader>
            <div className="table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th scope="col">{'Login'}</th>
                            <th scope="col">{'Deal Id'}</th>
                            <th scope="col">{'Position Id'}</th>
                            <th scope="col">{'Lots'}</th>
                            <th scope="col">{'Deal Type'}</th>
                            <th scope="col">{'Rebate'}</th>
                            <th scope="col">{'Symbol'}</th>
                            <th scope="col">{'Profit'}</th>
                        </tr>
                    </thead>
                    {dealWiseData.loading && <TableLoader />}
                    {!dealWiseData.loading && dealWiseData.data && dealWiseData.data.length === 0 && <TableNodata title="client deals" />}
                    
                    <tbody>
                        {
                            dealWiseData.data && dealWiseData.data.map((item, id) =>
                                <tr key={id}>
                                    <td >{item['SourceDeal.login']}</td>
                                    <td >{item['SourceDeal.dealId']}</td>
                                    <td >{item['SourceDeal.positionId']}</td>
                                    <td >{item['SourceDeal.volumeClosed']/10000}</td>
                                    <td >{item['SourceDeal.action'] === 1 ? "SELL" : "BUY"}</td>
                                    <td >{item.profit}</td>
                                    <td >{item['SourceDeal.symbol']}</td>
                                    <td >{item['SourceDeal.profit']}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <TablePagination data={dealWiseData} loadData={loadDealWiseSatement} />

            </div>
        </Card>}
    </>)
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setStatementDateRange(formValues)
    }
  };
  useEffect(()=>{
    loadData(1);
  }, [statementDateRange])
  return (
    <Fragment>
        <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='3 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Date From'
                                name = 'dateFrom'
                                register = {register}
                                placeholder= 'Select date from'
                                type='date'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='3 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Date To'
                                name = 'dateTo'
                                register = {register}
                                placeholder= 'Select date to'
                                type='date'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md={3} className="mt-4 pt-2">
                            <Btn disabled={rec.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        <Card>
            <CardHeader className='p-3'>
            <Row>
                <Col md={6} className="d-flex">
                    IB Statement
                </Col>
            </Row>
            </CardHeader>
        
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{'IB Account'}</th>
                        <th scope="col">{'Client Account'}</th>
                        <th scope="col">{'Rebate'}</th>
                        <th scope="col">{'Lots'}</th>
                    </tr>
                </thead>
                {rec.loading && <TableLoader />}
                {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="ib statement" />}
                
                <tbody>
                    {
                        rec.data && rec.data.map((item, id) =>
                            <tr key={id}>
                                <td >{item.login}</td>
                                <td >
                                    <span
                                        style={{textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer'}}
                                        onClick={()=>{setSelectedClient(item)}}
                                    >
                                        {item.clientLogin}
                                    </span>
                                </td>
                                <td >{item.rebate}</td>
                                <td >{item.volume/10000}</td>
                                
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} />

        </div>
        </Card>
        <DealWiseTable />

    </Fragment>
  );
};
export default IbStatement;