import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Media } from 'reactstrap';
import { H4 } from '../../../../../AbstractElements';

const SmallApexChart = ({accounts = {}, depositsFtd = {}, deposits = {}}) => {
  
  // "accounts":{
  //   "lastMonth":"6",
  //   "currentMonth":"1",
  //   "today":"1"
  //   },
  // "deposits":{
  //   "lastMonth":"593",
  //   "currentMonth":"100",
  //   "today":"100"
  //   },
  // "depositsFtd":{
  //   "lastMonth":{
  //   "deposits":"1761"
  //   },
  //   "currentMonth":{
  //   "deposits":null
  //   },
  //   "today":{
  //   "deposits":"100"
  //   }
  //   },
  return (
    <>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card>
          <CardHeader className='p-3'>
            Accounts
          </CardHeader>
          <CardBody>
            {accounts && 
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <H4>
                    <div>
                      {accounts.lastMonth || 0} $
                    </div>
                    <span className='new-box'>{'Last Month'}</span>
                  </H4>
                  <span>
                    {'Month to Date'} ({accounts.currentMonth || 0} $)
                  </span>
                </Media>
                <div>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {accounts.today || 0} $
                      </div>
                      <span className='new-box'>{'Today'}</span>
                    </H4>
                  </Media>
                </div>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card>
          <CardHeader className='p-3'>
            First Time Deposits
          </CardHeader>
          <CardBody>
            {depositsFtd && depositsFtd.lastMonth && 
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <H4>
                    <div>
                      {depositsFtd.lastMonth.deposits || 0} $
                    </div>
                    <span className='new-box'>{'Last Month'}</span>
                  </H4>
                  <span>
                    {'Month to Date'} ({depositsFtd.currentMonth.deposits || 0} $)
                  </span>
                </Media>
                <div>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {depositsFtd.today.deposits || 0} $
                      </div>
                      <span className='new-box'>{'Today'}</span>
                    </H4>
                  </Media>
                </div>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card>
          <CardHeader className='p-3'>
            Deposits
          </CardHeader>
          <CardBody>
            {accounts && 
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <H4>
                    <div>
                      {deposits.lastMonth || 0}
                    </div>
                    <span className='new-box'>{'Last Month'}</span>
                  </H4>
                  <span>
                    {'Month to Date'} ({deposits.currentMonth || 0} $)
                  </span>
                </Media>
                <div>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {deposits.today || 0} $
                      </div>
                      <span className='new-box'>{'Today'}</span>
                    </H4>
                  </Media>
                </div>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SmallApexChart;
