import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Form, Card, CardHeader, CardFooter, CardBody } from 'reactstrap';
// import { useSelector } from "react-redux";
import { ibService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
// import { getMenuPerm } from 'utils';
import { FInput } from 'CommonElements/Form';
import { useForm } from 'react-hook-form';
import { Btn, H6 } from "AbstractElements";
import { toast } from "react-toastify";



const GroupSetting = (props) => {
  const {
    customerId = '',
    activeTab,
    tabNumber,
  } = props;
  const [rec, setRec] = useState({});
//   const permissions = useSelector(
//     (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
//   );
  useEffect(()=>{
    if(activeTab === tabNumber) {
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    ibService.getGroupMapping(customerId).then((res) => {
        setRec({
            data: res,
            loading: false,
        })
        }).catch(err =>{
        });
  }
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const modalSubmit = formValues => {
    if (formValues !== '' && formValues.data) {
        ibService.setGroupMapping(customerId, formValues.data).then(res => {
            toast.success('IB groups updated, successfully');
            loadData();
        }).catch(err => {
            toast.error('Failed to update IB groups');

        });
    } else {
        errors.showMessages();
    }
};

useEffect(()=> {
    if(rec && rec.data && rec.data.length > 0) {
        rec.data.forEach((obj, index)=>{
            setValue(`data.${index}.accountTypeId`, obj.id);
            setValue(`data.${index}.groupPath`, obj.groupMapping?.groupPath??'');
            setValue(`data.${index}.groupPathSwapFree`, obj.groupMapping?.groupPathSwapFree??'');
        })
    }
}, [rec])

  return (
    <Fragment>
        <Card>
            <CardHeader className='p-3'>
                IB Group Setting
            </CardHeader>            
            <CardBody>
                {rec.loading && <TableLoader />}
                {!rec.loading && rec.data && rec.data.length > 0 &&
                     <Row>
                        <Col sm={12}>
                           
<Form  className="needs-validation" noValidate="" 
    onSubmit={handleSubmit(modalSubmit)}
>
        <Row>  
            {rec.data.map((mapping, index) => (<React.Fragment key={index}>
                    <Col md='6 mb-3' >
                        <FInput
                            name = {'data.'+index+'.accountTypeId'}
                            register = {register}
                            validation={{required: true}}
                            type="hidden"
                        />
                        <FInput
                            errors={errors}
                            label = {mapping.title + ' Group'}
                            name = {'data.'+index+'.groupPath'}
                            register = {register}
                            placeholder= 'Enter group Path'
                        />
                    </Col>
                    <Col md='6 mb-3' >
                        <FInput
                            errors={errors}
                            label = {mapping.title + ' Group Swap Free'}
                            name = {'data.'+index+'.groupPathSwapFree'}
                            register = {register}
                            placeholder= 'Enter group Path'
                        />
                    </Col>
                </React.Fragment>
            ))}
            <Col md={12} className="d-flex flex-row-reverse mt-4">
                <Btn disabled={rec.loading}  attrBtn={{ color: 'primary', type: 'submit' }} >{'Update Groups'}</Btn>
            </Col>
            
        </Row>

        {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
    </Form>

                        </Col>
                                            </Row>
                }
                
            </CardBody>

        </Card>

    </Fragment>
  );
};
export default GroupSetting;