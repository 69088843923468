import React, {useEffect, useState} from 'react';
import { Container, Row } from 'reactstrap';
import { Fragment } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import SummaryEarnings from '../Default/SummaryEarnings';
// import ChartDataRight from '../Default/ChartDataRight';
import Transactions from '../Default/Transactions/ApexChart';
import Requests from '../Default/Requests/ApexChart';
import Customers from '../Default/Customers/ApexChart';

// import NewsCard from "../Default/NewsCard";
// import AppointmentCard from "../Default/AppointmentCard"
// import AlertCard from '../Default/AlertCard';
// import NotificationCard from "../Default/NotifactionCard"
// import MarketValueCard from "../Default/MarketValueCard"
import { dashboardService } from "Services";

const Dashboard = () => {
    const [rec, setRec] = useState({});

    useEffect(()=>{
        loadData();
    },[]);
    const loadData = () => {
        setRec({
            loading: true,
            data: [],
            accounts: null,
            ibAccounts: null,
            deposits: null,
            withdrawals: null,
            depositsFtd: null,
            depositsSales: null,
          });
        Promise.all([
            dashboardService.getAccountsCount(),
            dashboardService.getIbAccountsCount(),
            dashboardService.getDepositsCount(),
            dashboardService.getWithdrawalsCount(),
            dashboardService.getDepositsFtd(),
            dashboardService.getDepositsBySales(),
        ]).then(([accounts, ibAccounts, deposits, withdrawals, depositsFtd, depositsSales]) => {
            setRec({
                loading: false,
                accounts, ibAccounts, deposits, withdrawals, depositsFtd, depositsSales
              });
        }).catch(err => {
            setRec({
                loading: false,
              });
        })

    }
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Dashboard"  />
            <Container fluid={true} className="dashboard-default-sec">
                <Row className="second-chart-list third-news-update">
                    {/* <GreetingCard /> */}
                    {/* <SummaryEarnings {...rec} /> */}
                    {/* <Col xl="9 xl-100" className="chart_data_left box-col-12">
                        <Card>
                            <CardBody className="p-0">
                                <Row className="m-0 chart-main">
                                    <ChartMain />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col > */}
                    {/* <ChartDataRight {...rec} />  */}
                    <Customers {...rec}/>
                    <Transactions {...rec} />
                    {/* <Requests {...rec} /> */}
                    {/* <NewsCard />
                    <Col xl="4 xl-50" className="appointment-sec box-col-6">
                        <Row>
                            <AppointmentCard />
                            <AlertCard />
                        </Row>
                    </Col>
                    <NotificationCard />
                    <MarketValueCard /> */}
                    {/* <CalendarCard /> */}
                </Row>
            </Container>
        </Fragment>
    );
};

export default Dashboard;
