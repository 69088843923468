import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import CommonModal from '../../../Common/Data/Ui-kits/Modals/common/modal';
import { leadService } from 'Services';
import { toast } from 'react-toastify';
import ExportXlsxList from './ExportXlsxList/index';
import { ModalFooter, Form, FormGroup, Col, Label } from 'reactstrap';
import { Close, SaveChanges } from '../../../../Constant';
var XLSX = require('xlsx');

const UploadLeadModal = ({ modal, NewMessage, toggle, setModal }, props) => {
  const [xlsxList, setXlsxList] = useState(null);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  useEffect(() => {
    if (modal == false) {
      setXlsxList(null);
    }
  }, [modal]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setXlsxList(jsonData);
    };
    reader.readAsBinaryString(file);
  };
  const { handleSubmit } = useForm();
  useEffect(() => {
    setXlsxList(null);
  }, []);
  const onSubmit = (data) => {
    if (xlsxList != null && xlsxList.length > 0) {
      var leadsList = [];
      xlsxList.map((x) => {
        var result = {
          firstName: x.firstName,
          lastName: x.lastName,
          email: x.phone + x.email,
          phone: x.phone,
          country: x.country,
          city: x.city,
        };
        leadsList.push(result);
      });
      leadService
        .postBulkLeads(leadsList)
        .then((res) => {
          toast.success('Lead Add Successfully');
          setXlsxList(null);
          setModal(false);
        })
        .catch((err) => {
          toast.error('Lead Already add');
        });
    }
  };
  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup className='form-group row'>
            <Col md={12}>
              <FormGroup className='row'>
                <Label className='col-sm-3 col-form-label'>
                  {'Upload Leads'}
                </Label>
                <Col sm='9'>
                  <input
                    onChange={handleFileUpload}
                    name='UploadLeads'
                    className='form-control'
                    type='file'
                  />
                </Col>
              </FormGroup>
            </Col>
          </FormGroup>
          <hr></hr>
          {xlsxList != null ? <ExportXlsxList xlsxData={xlsxList} /> : null}
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: toggle,
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
              // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default UploadLeadModal;
