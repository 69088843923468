import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FTextArea } from 'CommonElements/Form';
import CommonModal from '../../../Common/Data/Ui-kits/Modals/common/modal';
import { transactionsService } from 'Services';
import { toast } from 'react-toastify';
import { ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../../Constant';

const RejectFormModal = (
  {
    rejectModal,
    NewMessage,
    rejecttl,
    setRejectModal,
    rejectData,
    refreshRecord,
  },
  props
) => {
  const { data = {} } = props;
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const resetForm = () => {
    return reset({
      //   amount: "",
      reason: '',
      //   fee: "",
      note: '',
    });
  };
  useEffect(() => {
    if (!rejectModal) {
      clearErrors();
      resetForm();
    } else {
      const formFields = getValues();
      Object.keys(formFields).forEach((key) => {
        if (rejectData[key]) {
          setValue(key, rejectData[key]);
        }
      });
    }
  }, [rejectModal]);

  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      const payload = {
        // id: approvedData.id,
        note: data.note,
        reason: data.reason,
        // fee: data.fee.toString(),
      };

      transactionsService
        .postTransactionReject(payload, rejectData.id)
        .then((res) => {
          setSubmitState({
            loading: false,
          });
          refreshRecord();
          setRejectModal(false);
          toast.success('Reject Successfully');
        })

        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error('Reject Failed ');
        });
      // alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <CommonModal
        isOpen={rejectModal}
        title={NewMessage}
        toggler={rejecttl}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <Row style={{ marginBottom: 10 }}>
            {/* <Col md="6">
              <FInput
                errors={errors}
                type="number"
                label="Amount:"
                name="amount"
                value={data.amount}
                register={register}
                placeholder="Enter your amount"
                validation={{ required: true }}
                disabled
              />
            </Col>
            <Col md="6">
              <FInput
                type="number"
                errors={errors}
                label="Fee:"
                name="fee"
                value={data.fee}
                register={register}
                placeholder="Enter fee"
                validation={{ required: true }}
              />
            </Col>
          </Row> */}
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Reason:'
                name='reason'
                value={data.reason}
                register={register}
                placeholder='Enter Reason'
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Note:'
                name='note'
                value={data.note}
                register={register}
                placeholder='Enter Note'
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: () => setRejectModal(false),
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default RejectFormModal;
