import React, { useState } from "react";
import { Input, Label } from "reactstrap";

const Index = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  required,
  pattern,
  minLength,
  maxLength,
  elementType,
  option,
  disabled,
}) => {
  let inputElement = null;
  const [error, setError] = useState("");
  const [inputClasses, setinputClasses] = useState("");

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
    validateInput(e.target.value);
  };

  const validateInput = (value) => {
    if (required && !value) {
      setinputClasses("Invalid");
      setError("This field is required");
    } else if (pattern && !pattern.test(value)) {
      setError("Invalid input.");
    } else if (minLength && value.length < minLength) {
      setError("Input must be at least " + minLength + " characters.");
    } else if (maxLength && value.length > maxLength) {
      setError("Input must not exceed " + maxLength + " characters.");
    } else {
      setinputClasses("");
      setError("");
    }
  };
  switch (elementType) {
    case "input":
      inputElement = (
        <Input
          className={inputClasses}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className="form-control"
          onChange={handleOnChange}
          value={value}
          placeholder={placeholder}
        >
          {option.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      );
      break;
    case "textArea":
      inputElement = (
        <textarea type={type} value={value} placeholder={placeholder} />
      );
      break;
    default:
      break;
  }
  return (
    <div className="inputClass">
      <Label className="col-form-label" for="first-name">
        {label}
      </Label>
      {inputElement}
      {error !== "" ? (
        <div className="inputValidation">
          <span className="TQGan">
            <svg
              aria-hidden="true"
              className="stUf5b"
              fill="currentColor"
              focusable="false"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
            </svg>{" "}
            {error}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Index;
