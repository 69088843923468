import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import {
  EmailAddress,
  ForgotPassword,
  LoginWithJWT,
  Password,
  RememberPassword,
} from '../../../Constant';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../../../Services/auth.service';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GET_CONSTANTS_REQUESTED } from '../../../redux/actions/constants-action';
const LoginTab = ({
  selected,
  login: { loading, constants },
  getConstants,
}) => {
  // const [email, setEmail] = useState("dev@admin.com");  ----these this is staging connections
  // const [password, setPassword] = useState("dev123");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadings, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const loginWithJwt = (e) => {
    try {
      AuthService.login(email, password)
        // .then(handleResponse)
        .then(function (user) {
          setTimeout(() => {
            history(`${process.env.PUBLIC_URL}/dashboard`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        toast.error(
          'Oppss.. The password is invalid or the user does not have a password.'
        );
      }, 200);
    }
  };
  return (
    <Fragment>
      <Form className='theme-form'>
        <H4>{selected === 'firebase' ? 'Sign In With Firebase' : 'Sign In'}</H4>
        <P>{'Enter your email & password to login'}</P>
        <FormGroup>
          <Label className='col-form-label'>{EmailAddress}</Label>
          <Input
            className='form-control'
            type='email'
            required=''
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={email}
          />
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{Password}</Label>
          <Input
            className='form-control'
            type={togglePassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            defaultValue={password}
            required=''
          />
          <div
            className='show-hide'
            onClick={() => setTogglePassword(!togglePassword)}
          >
            <span className={togglePassword ? '' : 'show'}></span>
          </div>
        </FormGroup>
        <div className='form-group '>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className='checkbox ms-3 '>
              <Input id='checkbox1' type='checkbox' />
              <Label className='text-muted' for='checkbox1'>
                {RememberPassword}
              </Label>
            </div>
            <div className=''>
              <Link to={'/forget-password'}>{ForgotPassword}</Link>
            </div>
          </div>
          <Btn
            attrBtn={{
              color: 'primary',
              className: 'btn-block',
              disabled: loadings ? loadings : loadings,
              onClick: (e) => loginWithJwt(e),
            }}
          >
            {loadings ? 'LOADING...' : LoginWithJWT}
          </Btn>
        </div>
        {/* <SocialAuth /> */}
      </Form>
    </Fragment>
  );
};
LoginTab.propTypes = {
  loading: PropTypes.bool,
  constants: PropTypes.array,
  getConstants: PropTypes.func.isRequired,
  // deleteTodo: PropTypes.func.isRequired
};

// Get state to props
const mapStateToProps = (state) => ({
  login: state.login,
});

// Get dispatch / function to props
const mapDispatchToProps = (dispatch) => ({
  getConstants: () => dispatch({ type: GET_CONSTANTS_REQUESTED }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginTab);
