import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { bannersService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const BannerAdd = ({ loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)

  };
  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm({

  });
  

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);

        var formData = new FormData();
        formData.append("title", formValues.title);
        formData.append("description", formValues.description);
        formData.append("fileUrl", formValues['fileUrl'][0]);

        bannersService.addBanner(formData).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Banner added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Banner');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files);
}

  return (
    <Fragment>
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Add Banner'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Add New Banner'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Banner Title'
                                validation={{required: true}}
                            />
                        </Col>
                        
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Description'
                                name = 'description'
                                register = {register}
                                placeholder= 'Enter Description'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label">{'Image'}</Label>
                                <Col sm="9">
                                    <input onChange={handleChange} name="fileUrl" className="form-control" type="file" />
                                </Col>
                            </FormGroup>
                        </Col>
                        
                       
                        
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Add'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default BannerAdd;
