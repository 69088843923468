import api from './api';

export const getWallets = (customerId)=> {
    return api.get('/v1/crm/wallets?customerId='+customerId)
}

export const updateWallet = (id, data)=> {
    return api.patch('/v1/crm/wallets/'+id, data)
}

export const deleteWallet = (id)=> {
    return api.delete('/v1/crm/wallets/'+id)
}

export const addWallet = (data)=> {
    return api.post('/v1/crm/wallets/', data)
}