
import React, { Fragment, useState } from "react";

import SweetAlert from 'sweetalert2';
import { toast } from "react-toastify";

import { documentsService } from 'Services';
import TableRowReject from 'CommonElements/Table/TableRowReject';
import RejectDocumentModal from './RejectDocumentModal';

const DeleteDocument = ({ data={}, loadData }) => {

    const [show, setShow] = useState(false);

  const rejectDocument = () => {
    setShow(true);

};
const onSubmit = (formData) => {
    documentsService.updateDocument(data.id, formData).then(res => {
        setShow(false);
        toast.success('Document updated, successfully');
        loadData();
    }).catch(err => {
        setShow(false);
        toast.error('Failed to reject Document');
    })
}
  return (
    <Fragment>
        <TableRowReject onClick={rejectDocument} />
        <RejectDocumentModal show={show} toggle = {() => {setShow(!show)}} onSubmit={onSubmit}/>
    </Fragment>
  );
};

export default DeleteDocument;
