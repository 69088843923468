import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FTextArea, FSelect } from "CommonElements/Form";
import CommonModal from "../../../Common/Data/Ui-kits/Modals/common/modal";
import { transactionsService } from "Services";
import { toast } from "react-toastify";
import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";
import { Table } from 'reactstrap';

const ApprovedFormModal = (
  { show, toggle, data },
  
) => {

    const {details = {}, content = {}}= data;


  return (
    <Fragment>
      <CommonModal
        isOpen={show}
        title={'Log Details'}
        toggler={toggle}
        isButtonShown={true}
        
      >
        <Table>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(details).map(value => {
                    return (<tr>
                        <td>{value}</td>
                        <td>{details[value].toString()}</td>
                    </tr>)
                })}
                {Object.keys(content).map(value => {
                    return (<tr>
                        <td>{value}</td>
                        <td>{content[value].toString()}</td>
                    </tr>)
                })}
            </tbody>
        </Table>

      </CommonModal>
    </Fragment>
  );
};
export default ApprovedFormModal;
