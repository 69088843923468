import React, { Fragment, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import {
  GET_CONSTANTS_REQUESTED,
} from '../redux/actions/constants-action';
import {
  GET_PROFILE,
} from '../redux/actions/login-action';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const LayoutRoutes = ({getConstants, getProfile}) => {
  useEffect(()=>{
    getConstants();
    getProfile();
  }, [])
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}>
          <Route element={<AppLayout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
          </Fragment>
        ))}
        <Route path="*" element={ routes[routes.length -1].Component }/>
      </Routes>
    </>
  );
};

// export default LayoutRoutes;

LayoutRoutes.propTypes = {
  getConstants: PropTypes.func.isRequired,
  // deleteTodo: PropTypes.func.isRequired
}
// Get state to props
const mapStateToProps = (state) => ({
  login: state.login
})
const mapDispatchToProps = (dispatch) => ({
  getConstants: () => dispatch({ type: GET_CONSTANTS_REQUESTED }),
  getProfile: () => dispatch({ type: GET_PROFILE }),

})
export default connect(mapStateToProps, mapDispatchToProps)(LayoutRoutes)
