import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import InternalTransfersList from './InternalTransfersList';
import { Breadcrumbs } from '../../../AbstractElements';


const BasicTables = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Internal Transfer" mainTitle='' />
            <Container fluid={true}>
                <Row>
                    <InternalTransfersList />
                </Row>
            </Container>
        </Fragment>
    );
};

export default BasicTables;