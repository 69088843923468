import React, { useState, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddNewTask, AddTask, Close } from "../../Constant";
import { Check, Trash2, Edit } from "react-feather";
import { Btn, H4, LI, UL } from "../../AbstractElements";
import { FormGroup, Button } from "reactstrap";
import { userService, ticketService } from "Services";
import { Col, Form, Row, Container } from "reactstrap";
import { FInput, FSelect, FTextArea } from "CommonElements/Form";

const TodoList = (props) => {
  const { data = {} } = props;
  const [users, setUsers] = useState(null);
  const [assignToList, setAssignToList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
  } = useForm();
  useEffect(() => {
    const formFields = getValues();
    Object.keys(formFields).forEach((key) => {
      if (data[key]) {
        setValue(key, data[key]);
      }
    });
    getUserList();
    getTicketList();
  }, []);
  const getUserList = () => {
    var limit = 100;
    userService
      .getUsers({
        limit,
      })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
      });
  };
  const getTicketList = () => {
    ticketService
      .getTickets()
      .then((res) => {
        setTicketList(res.data);
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    if (users !== null && users !== undefined && users.length > 0) {
      var optionArr = [];
      users.map((opt) =>
        optionArr.push({
          name: opt.firstName + " " + opt.lastName + " - " + opt["Role.title"],
          value: opt.id,
        })
      );
      setAssignToList(optionArr);
    }
  }, [users]);
  const editBtn = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      const formFields = getValues();
      setSelectedRow(rowData);
      Object.keys(formFields).forEach((key) => {
        if (rowData[key]) {
          if (key === "startTime") {
            var newDate = new Date(rowData[key]);
            const dateOptions = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            };
            const formattedDate = newDate.toLocaleDateString(
              "en-CA",
              dateOptions
            );
            setValue(key, formattedDate);
          } else {
            setValue(key, rowData[key]);
          }
        }
        setAddTask(" visible");
        document.getElementById("addTaskBtn").classList.add("hide");
      });
    }
  };
  const resetTicketData = () => {
    clearErrors();
    reset({
      type: "",
      status: "",
      title: "",
      description: "",
      startTime: "",
      assignTo: "",
      note: "",
      priority: "",
    });
    setSelectedRow(null);
  };
  const objectReturn = (data) => {
    return {
      type: data.type,
      status: data.status,
      title: data.title,
      description: data.description,
      startTime: data.startTime,
      assignTo: data.assignTo,
      note: data.note,
      priority: data.priority,
    };
  };
  const onSubmit = (data) => {
    var payload = objectReturn(data);
    if (payload !== "" && selectedRow == null) {
      setSubmitState({ loading: true });
      ticketService
        .postTicket(payload)
        .then((res) => {
          getTicketList();
          closeTaskWrapper();
          toast.success("Ticket Added Successfully");
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error("Ticket Not Added ");
        });
    } else {
      updateTicket(payload, selectedRow.id);
    }
  };
  const updateTicket = (postData, selectedRowId) => {
    const { type, ...newObject } = postData;
    ticketService
      .updateTicket(newObject, selectedRowId)
      .then((res) => {
        getTicketList();
        toast.success("Ticket Update Successfully");
        closeTaskWrapper();
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        toast.error("Ticket Not Updated ");
      });
  };
  const [addTask, setAddTask] = useState("");
  const border_danger = useState("");
  const [status, setStatus] = useState("pending");

  const handleRemoveTodo = (todoId) => {
    ticketService
      .deleteTicket(todoId)
      .then((res) => {
        setTicketList(ticketList.filter((data) => data.id !== todoId));
        toast.success("Deleted Ticket !");
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        toast.error("Ticket Not Delete ");
      });
  };
  const findObjectIndex = (id) => {
    ticketList.findIndex(function (c) {
      return c.id === id;
    });
  };
  const updateObject = (id, newData) => {
    const index = findObjectIndex(id);
    if (index === -1) return;
    const updatedList = [...ticketList];
    updatedList[index] = { ...updatedList[index], ...newData };
    setTicketList(updatedList);
  };
  const handleMarkedTodo = (itemId, itemStatus) => {
    var data = ticketList.filter((obj) => {
      return obj.id === itemId;
    });
    var updateStatus = data[0].status === "completed" ? "pending" : "completed";
    data[0].status = updateStatus;
    var updatedRecord = data[0];
    updateObject(updatedRecord.id, updatedRecord);
    var payloadObj = objectReturn(updatedRecord);
    updateTicket(payloadObj, updatedRecord.id);
    if (updateStatus === "completed") {
      setStatus("pending");
      toast.success("Task Completed !");
    } else if (updateStatus === "pending") {
      setStatus("completed");
      toast.error(" Task In-completed !");
    }
  };
  const openTaskWrapper = () => {
    if (selectedRow !== null) {
      resetTicketData();
    }
    setAddTask(" visible");
    document.getElementById("addTaskBtn").classList.add("hide");
  };
  const closeTaskWrapper = () => {
    resetTicketData();
    setAddTask("");
    document.getElementById("addTaskBtn").classList.remove("hide");
  };
  return (
    <Fragment>
      <div className="todo-list-body">
        <UL attrUL={{ className: "simple-list", id: "todo-list" }}>
          {ticketList.length > 0
            ? ticketList.map((todo, index) => (
                <LI
                  attrLI={{ className: "task border-0 " + todo.status }}
                  key={todo.id}
                >
                  <div className="task-container">
                    <Row>
                      <Col md="6">
                        <H4 attrH4={{ className: "task-label" }}>
                          {todo.title.split(" ").slice(0, 5).join(" ")}... -{" "}
                          {todo.note.split(" ").slice(0, 5).join(" ")}...
                        </H4>
                      </Col>
                      <Col md="2">
                        <div className="assignToTicket">
                          <label>Assign to</label>
                          <span>
                            {todo["assigned.firstName"] +
                              " " +
                              todo["assigned.lastName"]}
                          </span>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="type">
                          <label>Type</label>
                          <span>{todo.type}</span>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="status">
                          <label>Status</label>
                          <span className={todo.status}>{todo.status}</span>
                        </div>
                      </Col>
                    </Row>

                    <span className="task-action-btn">
                      <span
                        className="action-box large delete-btn"
                        title="Edit Task"
                        onClick={(e) => editBtn(e, todo)}
                      >
                        <Edit />
                      </span>
                      <span
                        className="action-box large delete-btn"
                        title="Delete Task"
                        onClick={() => handleRemoveTodo(todo.id)}
                      >
                        <Trash2 />
                      </span>
                      <span
                        className="action-box large complete-btn"
                        title="Mark Complete"
                        onClick={() => handleMarkedTodo(todo.id, status)}
                      >
                        <Check />
                      </span>
                    </span>
                  </div>
                </LI>
              ))
            : ""}
        </UL>
      </div>
      <div className="todo-list-footer">
        <div className="add-task-btn-wrapper">
          <span className="add-task-btn" id="addTaskBtn">
            <Btn attrBtn={{ color: "primary", onClick: openTaskWrapper }}>
              {" "}
              {AddNewTask}
            </Btn>
          </span>
        </div>
        <div className={"new-task-wrapper" + addTask}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <FormGroup className="form-group row">
                <Col md="4 mb-3">
                  <FInput
                    errors={errors}
                    label="Title"
                    name="title"
                    register={register}
                    placeholder="Enter Title"
                    validation={{ required: true }}
                    value={data.title}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Type"
                    name="type"
                    register={register}
                    placeholder="Select Type"
                    options={[
                      { name: "Deposit", value: "Deposit" },
                      { name: "Withdrawal", value: "Withdrawal" },
                      { name: "Transaction", value: "Transaction" },
                      { name: "Development", value: "Development" },
                      { name: "Finance", value: "Finance" },
                      { name: "Marketting", value: "Marketting" },
                      { name: "HR", value: "HR" },
                      { name: "Support", value: "Support" },
                    ]}
                    validation={{ required: true }}
                    value={data.type}
                    setValue={setValue}
                    disabled={selectedRow == null ? false : true}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Status"
                    name="status"
                    register={register}
                    placeholder="Select Status"
                    options={[
                      { name: "To Do", value: "To Do" },
                      { name: "Pending", value: "pending" },
                      { name: "Complete", value: "completed" },
                      { name: "Close", value: "close" },
                    ]}
                    validation={{ required: true }}
                    value={data.status}
                    setValue={setValue}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    errors={errors}
                    label="Start time"
                    name="startTime"
                    register={register}
                    placeholder="Enter Start time"
                    validation={{ required: false }}
                    type="date"
                    value={data.startTime}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Priority"
                    name="priority"
                    register={register}
                    placeholder="Select Priority"
                    options={[
                      { name: "Very High", value: "Very High" },
                      { name: "High", value: "High" },
                      { name: "Normal", value: "Normal" },
                      { name: "Low", value: "Low" },
                    ]}
                    validation={{ required: true }}
                    value={data.priority}
                    setValue={setValue}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Assign To"
                    name="assignTo"
                    register={register}
                    placeholder="Select Assigne"
                    options={assignToList}
                    validation={{ required: true }}
                    value={data.assignTo}
                    setValue={setValue}
                  />
                </Col>
                <Col md="6 mb-3">
                  <FTextArea
                    errors={errors}
                    label="Note"
                    name="note"
                    className={"ng-untouched ng-pristine" + border_danger}
                    value={data.note}
                    register={register}
                    placeholder="Enter Note"
                    validation={{ required: true }}
                  />
                </Col>
                <Col md="6 mb-3">
                  <FTextArea
                    errors={errors}
                    label="Description"
                    name="description"
                    className={"ng-untouched ng-pristine" + border_danger}
                    value={data.description}
                    register={register}
                    placeholder="Enter Description"
                    validation={{ required: false }}
                  />
                </Col>
              </FormGroup>
            </Container>
            <Btn
              attrBtn={{
                color: "danger",
                className: "cancel-btn",
                id: "close-task-panel",
                onClick: closeTaskWrapper,
              }}
            >
              {Close}
            </Btn>
            <Button
              className="ms-3 add-new-task-btn"
              color="success"
              disable={submitState}
            >
              {AddTask}
            </Button>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};
export default TodoList;
