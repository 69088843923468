import React, { Fragment, useEffect, useState } from 'react';
import { ibService } from 'Services';
import { Table, Row, Col } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import LinkClient from './LinkClient';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import TablePagination from "CommonElements/Table/TablePagination";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const IbClients = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const [searchParams, setSearchParams] = useState({
    parentId: customerId,
    limit: 5
  });
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  useEffect(()=>{
    if(activeTab === tabNumber) {
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = (page = 1) => {
    setSearchParams({
        ...searchParams,
        page,
    })
    ibService.getClients({...searchParams, page}).then((res) => {
        setRec({
            ...res,
            loading: false,
        })
        }).catch(err =>{
        });
  }
  const unlinkClient = (client, id) => {
    ibService.unlinkClient(client.id).then(res => {
        toast.success('Client unlinnked, successfully');
        rec.data.splice(id, 1);
        loadData();
    }).catch(err => {
        toast.error('Failed to unlink Client');
    });
  }
  const getPageName = (item) => {
    if(item.isIb) return 'ibs';
    if(item.isClient || item.isCorporate) return 'clients';
    return 'leads';
  }
  return (
    <Fragment>
        <Row>
            <Col md={12} className="d-flex flex-row-reverse">
                {getMenuPerm(permissions, 'transactions:create') === 1 &&
                    <LinkClient customerId={customerId}  loadData={loadData} />
                }
            </Col>
        </Row>
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{'#'}</th>
                        <th scope="col">{'First Name'}</th>
                        <th scope="col">{'Last Name'}</th>
                        <th scope="col">{'Email'}</th>
                        <th scope="col">{'Client Type'}</th>
                        <th scope="col">{'Phone'}</th>
                        <th scope="col">{'Country'}</th>
                        <th scope="col">{'Registered Date'}</th>
                        <th scope="col">{'Actions'}</th>
                        
                    </tr>
                </thead>
                {rec.loading && <TableLoader />}
                {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="IB Clients" />}
                
                <tbody>
                    {
                        rec.data && rec.data.map((item, id) =>
                            <tr key={id}>
                                <th scope="row">{item.id}</th>
                                <td >{item.firstName}</td>
                                <td >{item.lastName}</td>
                                <td >
                                    <Link to={`${process.env.PUBLIC_URL}/${getPageName(item)}/${item.id}`}>{item.email}</Link>
                                </td>
                                <td >
                                    {item.isLead === 1 && 'Lead'}
                                    {item.isClient === 1 && ' Client'}
                                    {item.isIb === 1 && ' IB'}
                                    {item.isCorporate === 1 && ' Corporate'}
                                </td>
                                <td >{item.phone}</td>
                                <td >{item.country}</td>
                                <td >{item.createdAt}</td>
                                <td>
                                <span onClick={()=>{unlinkClient(item, id)}} className="m-2 font-danger" style={{cursor: 'pointer'}}>
                                    Unlink 
                                </span>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} />

        </div>
    </Fragment>
  );
};
export default IbClients;