/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_LOADING,
    GET_CONSTANTS,
  } from '../actions/constants-action'
  
  // Define your state here
  const initialState = {
    loading: false,
    constants: [],
  }
  
  // This export default will control your state for your application
  export default(state = initialState, {type, payload}) => {
    switch(type) {
      // Set loading
      case SET_LOADING:
        return {
          ...state,
      loading: true
        }
      // Get LOGIN
      case GET_CONSTANTS:
        return {
          ...state,
          constants: payload,
          loading: false
        }
      default:
        return state
    }
  }