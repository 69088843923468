import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ibService } from 'Services';
import CommonModal from 'Components/Common/Data/Ui-kits/Modals/common/modal';
import { FSelect, FInput, FClientSelect } from 'CommonElements/Form';
import gateways from '../../../Common/Component/Gateways';
import { Btn } from 'AbstractElements';

const LinkClient = ({ customerId, loadData, accounts = [] }) => {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
    
  } = useForm({
    // mode: 'onChange'
  });
  const [agreements, setAgreements] = useState({loading: false});
  const loadAgreements = () => {
    ibService.getAgreements(customerId).then((res) => {
      setAgreements({...res, loading: false,})
    }).catch(err =>{
      setAgreements({loading: false,})
    });
  }
  useEffect(()=>{
    loadAgreements();
  }, [])

  const formValueChange = (name, value) => {
    setValue(name, value);
  };

  const modalSubmit = (formValues) => {
    if (formValues !== '') {
      const params = formValues;
      params.parentId = customerId;
      setLoading(true);
      ibService
        .linkClient( params)
        .then((res) => {
          setLoading(false);
          toggle();
          reset();
          toast.success('Client Linked, successfully');
          loadData();
        })
        .catch((err) => {
          setLoading(false);
          toast.error('Failed to link client');
        });
    } else {
      errors.showMessages();
      toast.error('Failed to link client');
    }
  };
  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary', className: 'mb-3' }}>
        {'Link Client'}
      </Btn>

      <CommonModal
        isOpen={modal}
        title={'Link Client'}
        toggler={toggle}
        formSubmit={() => {}}
        hideFooter={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='12 mb-3'>
              <FClientSelect
                control={control}
                errors={errors}
                label = 'Select client to Link'
                name = 'id'
                validation={{required: true}}
                setValue={setValue}
                value={''}
                defaultOptions={[]}
                setLabel={(e)=>{
                  return `${e.firstName} ${e.lastName}-${e.email}`
                }}
                notUnderIb={true}
              />
            </Col>
            <Col md='12 mb-3'>
              <FSelect
                control={control}
                errors={errors}
                label = 'Agreement'
                name = 'agreementId'
                register = {register}
                placeholder= 'Select agreement to Link'
                options = {agreements?.data?.map(obj=>{return {
                  value: obj.id,
                  name: obj.title
                }})}
                validation={{required: true}}
                setValue={setValue}
            />
            </Col>
            
            <Col md={12} className='d-flex flex-row-reverse'>
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>
                {'Add'}
              </Btn>
            </Col>
          </Row>

          {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default LinkClient;
