import React, { Fragment, useState } from "react";
import { Btn } from "../../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import LeadFormModal from "../../Pages/Leads/LeadFormModal";

const LeadModalButton = ({ btnText, defaultVal, loadData }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Btn attrBtn={{ color: "primary", onClick: toggle  }} >{btnText}</Btn>
      <LeadFormModal
        loadData={loadData}
        modal={modal}
        NewMessage="Add Leads"
        toggle={toggle}
        defaultVal={defaultVal}
        float = "right"
      />
    </Fragment>
  );
};

export default LeadModalButton;
