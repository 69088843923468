import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FTextArea, FSelect } from 'CommonElements/Form';
import gateways from '../../Common/Component/Gateways';

import CommonModal from '../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { transactionsService, BankAccount } from 'Services';
import { toast } from 'react-toastify';

import { ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../Constant';

const WithdrawalFormModal = (
  { modal, NewMessage, toggle, setModal, refreshRecord },
  props
) => {
  const { data = {} } = props;
  const [isAccounInputDisable, setIsAccounInputDisable] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
  } = useForm();
  const formValueChange = (name, value) => {
    setValue(name, value);
  };
  const resetForm = () => {
    return reset({
      login: '',
      accountTypeId: '',
      gateway: '',
      amount: '',
      reason: '',
      // fee: '',
      note: '',
    });
  };
  useEffect(() => {
    if (!modal) {
      clearErrors();
      resetForm();
      setIsAccounInputDisable(false);
    }
  }, [modal]);

  // 5secondDelay
  const handleLoginChange = (e) => {
    e.preventDefault();
    const loginNo = e.target.value;
    setValue('login', loginNo);
    // searchTimer =
    setTimeout(startSearch, 3000);
  };
  const startSearch = () => {
    const formFields = getValues();
    var loginNo = formFields.login;
    if (loginNo != null && loginNo !== '') {
      setIsAccounInputDisable(false);
      setValue('accountTypeId', '');
      BankAccount.getAccountSearch(`?login=${loginNo}`)
        .then((res) => {
          if (res.id != null) {
            setValue('accountTypeId', res.id);
            setIsAccounInputDisable(true);
          }
        })
        .catch((err) => {
        });
    }
  };
  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      const payload = {
        accountId: Number(data.accountTypeId),
        gateway: data.gateway,
        note: data.note,
        reason: data.reason,
        // fee: data.fee,
        amount: data.amount,
      };

      transactionsService
        .postWithdrawals(payload)
        .then((res) => {
          setSubmitState({
            loading: false,
          });
          setModal(false);
          toast.success('Withdrawal Added Successfully');
          refreshRecord();
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error(' Add Withdrawal failed ');
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Login No:'
                name='login'
                register={register}
                placeholder='Enter Login No'
                value={data.login}
                validation={{ required: true }}
                handleOnChange={(e) => handleLoginChange(e)}
              />
            </Col>
            <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Account No:'
                name='accountTypeId'
                register={register}
                placeholder='Enter Account No'
                value={data.accountTypeId}
                validation={{ required: true }}
                disabled={isAccounInputDisable}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                errors={errors}
                type='number'
                label='Amount:'
                name='amount'
                value={data.amount}
                register={register}
                placeholder='Enter your amount'
                validation={{ required: true }}
              />
            </Col>
            <Col md='6'>
              <FSelect
                control={control}
                errors={errors}
                label='Gateway'
                name='gateway'
                register={register}
                placeholder='Enter gateway'
                options={gateways}
                validation={{ required: true }}
                value={''}
                setValue={formValueChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FInput
                errors={errors}
                label='Reason:'
                name='reason'
                value={data.reason}
                register={register}
                placeholder='Enter Reason'
                validation={{ required: true }}
              />
            </Col>
            {/* <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Fee:'
                name='fee'
                value={data.fee}
                register={register}
                placeholder='Enter fee'
                validation={{ required: true }}
              />
            </Col> */}
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Note:'
                name='note'
                value={data.note}
                register={register}
                placeholder='Enter Note'
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: () => setModal(false),
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
              // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default WithdrawalFormModal;
