import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getBanners = (params={}) => {
  return api.get("v1/crm/banners?" + queryString(params));
};

export const deleteBanner = (id) => {
  return api.delete("v1/crm/banners/" + id);
};

export const addBanner = (params) => {
  return api.post("v1/crm/banners", params);
};

export const updateBanner = (id, params) => {
  return api.patch("v1/crm/banners/"+id, params);
};
