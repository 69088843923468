// Import the redux-saga/effects
import {
    put,
    call,
    takeLatest,
    takeEvery
  } from 'redux-saga/effects'
  
  // Import all actions and api's
  import {
    SET_LOADING,
    GET_LOGIN,
    GET_LOGIN_REQUESTED,
    SET_LOGIN_TITLE,
    SET_LOGIN_TITLE_REQUESTED,
    CLEAR_LOGIN_TITLE,
    CREATE_LOGIN,
    CREATE_LOGIN_REQUESTED,
    DELETE_LOGIN,
    DELETE_LOGIN_REQUESTED,
    GET_PROFILE,
    GET_PROFILE_DONE
  } from '../actions/login-action'
  import {
    authService,
  } from '../../Services'
  // Import all api's
  import {
    getAlllogin,
    createNewLogin,
    deleteExistedLogin,
  } from '../api/login-api'
  
  // Here's the unique part, generator function*, function with asterisk(*)
  
  // Get LOGIN
  function* getLogin() {
    yield put({ type: SET_LOADING })
  
    const LOGIN = yield call(getAlllogin)
  
    yield put({ type: GET_LOGIN, payload: LOGIN })
  }
  
  // Set the title of login
  function* setLoginTitle({ payload }) {
    yield put({ type: SET_LOGIN_TITLE, payload })
  }
  
  // Create login
  function* createLogin({ payload }) {
    yield put({ type: SET_LOADING })
  
    const newLogin = yield call(createNewLogin, payload)
  
    yield put({ type: CREATE_LOGIN, payload: newLogin })
    
    // Clear login after creating
    yield put({ type: CLEAR_LOGIN_TITLE })
  }
  
  // Delete login
  function* deleteLogin({ payload }) {
    yield put({ type: SET_LOADING })
  
    const login = yield call(deleteExistedLogin, payload)
  
    yield put({ type: DELETE_LOGIN, payload: login })
  }
  

   // Fetch Profile
   function* fetchProfile({ payload }) {
    yield put({ type: SET_LOADING })
  
    const profile = yield call(authService.default.getProfile, payload)
  
    yield put({ type: GET_PROFILE_DONE, payload: profile })
  }
  
  // Export the saga (login-saga)
  export default function* loginSaga() {
    yield takeEvery(GET_LOGIN_REQUESTED, getLogin)
    yield takeEvery(SET_LOGIN_TITLE_REQUESTED, setLoginTitle)
    yield takeLatest(CREATE_LOGIN_REQUESTED, createLogin) 
    yield takeEvery(DELETE_LOGIN_REQUESTED, deleteLogin)
    yield takeEvery(GET_PROFILE, fetchProfile)

  }