// Import the redux-saga/effects
import {
    put,
    call,
    takeEvery
  } from 'redux-saga/effects'
  
  // Import all actions and api's
  import {
    SET_LOADING,
    GET_CONSTANTS,
    GET_CONSTANTS_REQUESTED,
  } from '../actions/constants-action'
  
  // Import all api's
  import {
    configService,
  } from '../../Services'

  const { getConstants } = configService;
  
  // Here's the unique part, generator function*, function with asterisk(*)
  
  // Get LOGIN
  function* getConstFunc() {
    yield put({ type: SET_LOADING })
  
    const CONSTANTS = yield call(getConstants)
  
    yield put({ type: GET_CONSTANTS, payload: CONSTANTS })
  }
  
  
  // Export the saga (login-saga)
  export default function* loginSaga() {
    yield takeEvery(GET_CONSTANTS_REQUESTED, getConstFunc)
  }