import React from 'react';

const TableRowReject = ({
    onClick = () => {}
}) =>(
    <span onClick={onClick} className="m-2 font-danger" style={{cursor: 'pointer'}}>
        <i className="fa fa-times"></i>
    </span>
);

export default TableRowReject;