import React from 'react';

const TableRowEdit = ({
    onClick = () => {}
}) =>(
    <span onClick={onClick} className="m-2 font-info" style={{cursor: 'pointer'}}>
        <i className="fa fa-pencil"></i>
    </span>
);

export default TableRowEdit;