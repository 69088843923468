import React, { Fragment, useState } from "react";
// import getListRecord from './InternalTransfersList'
import { Btn } from "../../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import InterDrawalFormModal from "./InterFormModal";

const InterModalButton = ({ btnText, defaultVal, refreshRecord},props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Btn attrBtn={{ color: "primary", onClick: toggle }}>{btnText}</Btn>
      <InterDrawalFormModal
        modal={modal}
        NewMessage="Add Internal Transfer"
        toggle={toggle}
        defaultVal={defaultVal}
        setModal={setModal}
        refreshRecord={refreshRecord}
      />
    </Fragment>
  );
};

export default InterModalButton;