import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, CardHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FUserSelect, FSelect } from 'CommonElements/Form';
import { clientService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const BasicInformation = (props) => {
  const {
    customerId = '',
    data = {},
  } = props;
  const COUNTRIES = useSelector(
    (state) => (state.config.constants.COUNTRIES )
  );
  const [submitState, setSubmitState] = useState({
    loading: false,
  })
  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
  useEffect(()=>{
    const formFields = getValues();
    Object.keys(formFields).forEach(key => {
      if(data[key]) {
        setValue(key, data[key])
      }
    })
  }, [])
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const onSubmit = data => {
    if (data !== '') {
        setSubmitState({loading: true});
        clientService.updateClient(customerId, data).then(res => {
            setSubmitState({
                loading: false,
                status: 'Profile updated sucessfully'
            })
            SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'error' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'info' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'warning' });

        }).catch(err => {
            setSubmitState({
                loading: false,
                status: 'Error in updating Profile'
            })
            SweetAlert.fire({ title: 'Failed', text: 'Error in updating Profile!', icon: 'error' });
        });
        // alert('You submitted the form and stuff!');
    } else {
        errors.showMessages();
    }
};

  return (
    <Fragment>
        <Card>
            <CardHeader className='p-3'>
                Basic Information Lead
            </CardHeader>
            <CardBody>
                <Form  className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'First Name'
                                name = 'firstName'
                                register = {register}
                                placeholder= 'Enter First Name'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Last Name'
                                name = 'lastName'
                                register = {register}
                                placeholder= 'Enter Last Name'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email'
                                name = 'email'
                                register = {register}
                                placeholder= 'Enter Email Address'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Phone'
                                name = 'phone'
                                register = {register}
                                placeholder= 'Enter Phone Number'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                           
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Call Status'
                                name = 'callStatus'
                                register = {register}
                                placeholder= 'Enter Call Status'

                                options = {[
                                    {name: 'Not contacted ', value: 'Not contacted '},
                                    {name: 'Not Interested', value: 'Not Interested'},
                                    {name: 'Not Reachable ', value: 'Not Reachable '},
                                    {name: 'Cold -  call later ', value: 'Cold -  call later '},
                                    {name: 'Warm -  call after 10 days ', value: 'Warm -  call after 10 days '},
                                    {name: 'Hot - Intrested ', value: 'Hot - Intrested '},
                                    {name: 'Wrong Number', value: 'Wrong Number'},
                                    {name: 'Invalid number ', value: 'Invalid number '},
                                    {name: 'Do not call again ', value: 'Do not call again '},
                                ]}
                                
                                
                                validation={{required: true}}
                                value={data.callStatus}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Source'
                                name = 'source'
                                register = {register}
                                placeholder= 'Enter Source'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FUserSelect
                                control={control}
                                errors={errors}
                                label = 'Select Assigned User'
                                name = 'agentId'
                                validation={{required: true}}
                                setValue={setValue}
                                value={data.agentId}
                                defaultOptions={data.Agent ? [data.Agent]:[]}
                                
                                // isClient={true}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Country of Residence'
                                name = 'country'
                                register = {register}
                                placeholder= 'Enter Country of Residence'
                                validation={{required: true}}
                                value={data.country}
                                options = {COUNTRIES && COUNTRIES.map(obj => {
                                    return {
                                        name: obj.text,
                                        value: obj.text
                                    }
                                })}  
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Modified Time'
                                name = 'updatedAt'
                                // register = {register}
                                disabled={true}
                                placeholder= 'Lead Update Time'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Lead Supplier'
                                name = 'supplier'
                                register = {register}
                                placeholder= 'Lead Supplier'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Page Url'
                                name = 'pageUrl'
                                register = {register}
                                placeholder= 'Page Url'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Website Langauge'
                                name = 'language'
                                register = {register}
                                placeholder= 'Website Langauge'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Affiliate ID'
                                placeholder= 'Affiliate ID'
                                name = 'affiliateId'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Campaign'
                                placeholder= 'UTM Campaign'
                                name = 'utmCampaign'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Source'
                                placeholder= 'UTM Source'
                                name = 'utmSource'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Medium'
                                placeholder= 'UTM Medium'
                                name = 'utmMedium'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Term'
                                placeholder= 'UTM Term'
                                name = 'utmTerm'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Content'
                                placeholder= 'UTM Content'
                                name = 'utmContent'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Category'
                                placeholder= 'UTM Category'
                                name = 'utmCategory'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Website'
                                placeholder= 'Website'
                                name = 'website'
                                register = {register}
                            />
                        </Col>








                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Address Steet'
                                name = 'address'
                                register = {register}
                                placeholder= 'Enter Address Street'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter PO Box'
                                name = 'address2'
                                register = {register}
                                placeholder= 'Enter PO Box'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Postal Code'
                                name = 'zipCode'
                                register = {register}
                                placeholder= 'Enter Postal Code'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'City'
                                name = 'city'
                                register = {register}
                                placeholder= 'Enter City'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'State'
                                name = 'state'
                                register = {register}
                                placeholder= 'Enter State'
                            />
                        </Col>

                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Nationality'
                                name = 'nationality'
                                register = {register}
                                placeholder= 'Enter Nationality'
                                validation={{required: true}}
                                value={data.nationality}
                                options = {COUNTRIES && COUNTRIES.map(obj => {
                                    return {
                                        name: obj.text,
                                        value: obj.text
                                    }
                                })}  
                                setValue={setValue}
                            />
                        </Col>

                    </Row>
                    
                    {getMenuPerm(permissions, 'leads:update') === 1 &&
                        <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                    }
                </Form>
            </CardBody>
        </Card>
    </Fragment>
  );
};
export default BasicInformation;
