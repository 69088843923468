import React, { Fragment, useState } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { clientService } from 'Services';
import AsyncSelect from 'react-select/async';

const FormInput = ({
    errors = {},
    label = '',
    name = '',
    validation={},
    value: defValue,
    setValue = () => {},
    control,
    isClient,
    isLead,
    isIb,
    notUnderIb,
    setLabel = (e) => {return `${e.firstName} ${e.lastName}`}
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const customerType = {};
  if(isClient !== undefined) {
    customerType.isClient = isClient;
  }
  if(isLead !== undefined) {
    customerType.isLead = isLead;
  }
  if(isIb !== undefined) {
    customerType.isIb = isIb;
  }
  if(notUnderIb !== undefined) {
    customerType.notUnderIb = notUnderIb;
  }
  
 
  // handle selection
  const handleChange = e => {
    setSelectedValue(e);
    setValue(name, e.id);

  }
 
  // load options using API call
  const loadOptions = (inputValue) => {
    return new Promise(async(resolve , reject) => {
      return clientService.getCustomersDropdown(inputValue, customerType).then(res => {
        return resolve(res);
      }).catch(err => {
        resolve([])
      });
    })

  };
  return (
    <Fragment>
        <FormGroup>
            <Label className="form-label" for={name}>{label}</Label>
          {control && 
            <Controller
                  control={control}
                  defaultValue={defValue}
                  name={name}
                  id={name}
                  // className="form-select" 
                  isSearchable={true}
                  isClearable
                  // className="react-dropdown"
                  // classNamePrefix="dropdown"
                  // {...register(name, validation)}
                  rules={validation}
                  render={({ 
                    field: { onChange, onBlur, value, name, ref }
                  }) => (
                    <AsyncSelect
                    // cacheOptions
                    defaultOptions
                    value={selectedValue}
                    getOptionLabel={e => setLabel(e)}
                    getOptionValue={e => e.id}
                    loadOptions={loadOptions}
                    // onInputChange={handleUpdate}
                    onChange={(e)=>{onChange(e); handleChange(e)}}
                  />
                      
                  )}
              />
          //     <AsyncSelect
          //     inputRef={ref}
          //     onBlur={onBlur} // notify when input is touched
          //     classNamePrefix="addl-class"
          //     // options={newOptions}
          //     loadOptions={searchClients} 
          //     value={newOptions.find(c => c.value === value)}
          //     onChange={(e)=>{onChange(e); handleUpdate(e)}}
          // />
            }
            <span className='error'>{errors[name] && `${label} is ${errors[name].type}`}</span>
        </FormGroup>
    </Fragment>
  );
};
export default FormInput;
