import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table, Card, CardHeader, CardFooter, CardBody } from 'reactstrap';

const Agreements = ({agreement={}}) => {
    return (<React.Fragment>
        <Card>
            <CardHeader className='p-3'>
                {agreement?.title}
            </CardHeader>
            <CardBody>
                {agreement?.members?.map((member, memIndex) => {
                    return (<React.Fragment>
                        <p>IB - {member?.Member?.firstName +' ' + member?.Member?.lastName}</p>
                        {member?.AccountTypeValues?.map((accType, acctypeInd) => {
                            return (<React.Fragment>
                                <p className='mb-0'>{accType?.AccountType?.title} - {accType?.rebate}</p>
                            </React.Fragment>)
                        })}
                        <hr />
                    </React.Fragment>)
                })}
            </CardBody>
        </Card>
    </React.Fragment>)
};
export default Agreements;