import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Users } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { userService } from "Services";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Btn } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Trash2, Edit } from "react-feather";
import AddUserModal from "./AddUser/addUser";
import { FSwitch } from "CommonElements/Form";
import { toast } from "react-toastify";
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

const HoverableRowsClass = () => {
  const [modal, setModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const toggle = () => {
    setEditUser(null);
    setModal(!modal);
  };
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    userService
      .getUsers({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };
  function delbtn(id) {
    Swal.fire({
      title: "Are you sure you want to delete?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        userService
          .delUsers(id)
          .then((resDelUser) => {
            if (resDelUser) {
              Swal.fire("Deleted!", "User has been deleted.", "success");
              setModal(false);
            }
          })
          .catch((err) => {
            Swal.fire("Not Deleted!", "User has not been  deleted.", "error");
          });
        //
      }
    });
  }

  const editBtn = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setEditUser({ ...rowData });
    }
    setModal(!modal);
    // Swal.fire({
    //   title: "Are you sure you want to edit this?",
    //   cancelButtonText: "No",
    //   confirmButtonText: "Yes",
    //   reverseButtons: true,
    //   showCancelButton: true,
    // });
  };
  const changeStatus = (item, index) => {
    userService
      .updateUser(
        {
          isActive: !item.isActive,
        },
        item.id
      )
      .then((res) => {
        rec.data[index].isActive = !item.isActive;
        toast.success("User updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update User");
      });
  };
  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="8">
                {" "}
                <H5>{Users}</H5>
              </Col>
              <Col md="4">
                <Row className="float-end">
                  <Col>
                  {getMenuPerm(permissions, 'users:create') === 1 &&
                    <Btn attrBtn={{ color: "primary", onClick: toggle }}>
                      Add
                    </Btn>
                  }
                      <AddUserModal
                        modal={modal}
                        NewMessage="Add User"
                        toggle={toggle}
                        setModal={setModal}
                        editData={editUser}
                      />
                  </Col>
                  {/* <Col md="6">
                            <Link className='btn btn-secondary' to={'/godo/detail'}> Users Detail
                    </Link>
                            </Col> */}
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"First Name"}</th>
                  <th scope="col">{"Last Name"}</th>
                  <th scope="col">{"Email"}</th>
                  <th scope="col">{"Role"}</th>
                  <th scope="col">{"Phone"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              {rec.loading && <TableLoader />}
              {!rec.loading && rec.data && rec.data.length === 0 && (
                <TableNodata title="Users" />
              )}
              <tbody>
                {rec.data &&
                  rec.data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item["Role.title"] || "---"}</td>
                      <td>{item.phone}</td>
                      <td>
                        {getMenuPerm(permissions, 'users:update') === 1 &&
                          <FSwitch
                          onChange={() => {
                            changeStatus(item, id);
                          }}
                          value={item.isActive}
                          oncha
                          />
                        }
                      </td>

                      <td>
                        {item.action}
                        {getMenuPerm(permissions, 'users:update') === 1 &&
                          <a href="#javascript" className="tble_edit">
                            <Edit
                              onClick={(e) => editBtn(e, item)}
                              style={{ color: "blue" }}
                              />
                          </a>
                        }
                        {getMenuPerm(permissions, 'users:delete') === 1 &&
                          <a href="#javascript" className="tble_delete">
                            <Trash2
                              onClick={() => delbtn(item.id)}
                              style={{ color: "red" }}
                              />
                          </a>
                          }
                        {/* <Button onClick={editBtn} color="primary" size="sm">
                            <i className="fa fa-pencil"></i> {""}
                            {Edit}
                          </Button>
                          {"  "}
                          <Button
                            onClick={() => delbtn(item.id)}
                            color="danger"
                            size="sm"
                          >
                            <i className="fa fa-trash"></i> {""}
                            {Delete}
                          </Button>{" "} */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
