import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table, Container } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Breadcrumbs } from "../../../AbstractElements";
import { requestsService } from "Services";
import TableRowApprove from "CommonElements/Table/TableRowApprove";
import TableRowReject from "CommonElements/Table/TableRowReject";
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import TableNodata from "CommonElements/Table/TableNodata";
import TableLoader from "CommonElements/Table/TableLoader";

const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    requestsService
      .getRequests({ type: "ACCOUNT" })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };
  const rejectRequest = (id) => {
    Swal.fire({
      title: "Are you sure you want to Reject?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once rejected, you will not be able to approve this request!",
      icon: "error",
    }).then((res) => {
      if (res.isConfirmed) {
        alert("Item is Deleted");
      }
    });
  };

  const approveRequest = (id) => {
    Swal.fire({
      title: "Are you sure you want to Approve?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once approved, you will not be able to approve this request!",
      icon: "success",
    }).then((res) => {
      if (res.isConfirmed) {
        alert("Item is Deleted");
      }
    });
  };
  return (
    <Fragment>
      <Breadcrumbs title="Account Requests" mainTitle="" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    <H5>{"Account Requests"}</H5>
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Client"}</th>
                      <th scope="col">{"KYC Status"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  {rec.loading && <TableLoader />}
                  {!rec.loading && rec.data && rec.data.length === 0 && (
                    <TableNodata title="Account Requests" />
                  )}
                  <tbody>
                    {rec.data &&
                      rec.data.map((item, id) => (
                        <tr key={id}>
                          <td>{item.id}</td>
                          <td>{`${item["Customer.firstName"]} ${item["Customer.lastName"]}`}</td>
                          <td>{`${item["Customer.kycStatus"]}`}</td>
                          <td>
                            <TableRowStatus status={item.status} />
                          </td>
                          <td>
                            <TableRowApprove
                              onClick={(e) => {
                                approveRequest(item.id);
                              }}
                            />
                            <TableRowReject
                              onClick={(e) => {
                                rejectRequest(item.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default HoverableRowsClass;
