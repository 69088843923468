const logo =

<svg id="Layer_1" height={60} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 963.41 478.05"><defs>
</defs>
<path className="cls-1" d="M57.05,391.7l-7.89-4.8q-7.44-4.53-10.57-8.92a16.91,16.91,0,0,1-3.15-10.11,17.84,17.84,0,0,1,6-13.92q5.94-5.35,15.45-5.35a29.28,29.28,0,0,1,16.68,5.1v11.8q-7.84-7.56-16.89-7.55a14,14,0,0,0-8.36,2.35,7.15,7.15,0,0,0-3.27,6,9.27,9.27,0,0,0,2.41,6.11,30.39,30.39,0,0,0,7.75,6l7.94,4.69Q76.38,391,76.38,403.3a18.53,18.53,0,0,1-5.85,14.18q-5.85,5.46-15.19,5.46a32,32,0,0,1-19.59-6.61v-13.2q8.41,10.67,19.48,10.66a12.22,12.22,0,0,0,8.13-2.71,8.53,8.53,0,0,0,3.25-6.81Q66.61,397.64,57.05,391.7Z"/>
<path className="cls-1" d="M79.8,381.9l17.77-17.45v9.86h15.12v8.52H97.57v23.39q0,8.19,6.8,8.19A21.13,21.13,0,0,0,115.13,411v8.83a23.82,23.82,0,0,1-11.9,3.07,15.74,15.74,0,0,1-10.8-3.8,10.87,10.87,0,0,1-2.24-2.57,13.66,13.66,0,0,1-1.48-3.74,41.74,41.74,0,0,1-.6-8.8v-21.2H79.8Z"/>
<path className="cls-1" d="M144.79,374.21q10.91,0,18.13,7a23.69,23.69,0,0,1,7.22,17.69,22.48,22.48,0,0,1-7.33,17.16q-7.32,6.84-18.44,6.84a24.93,24.93,0,0,1-18-6.94,24.48,24.48,0,0,1,.08-34.8Q133.77,374.21,144.79,374.21Zm-.52,8.52a14.93,14.93,0,0,0-11.17,4.47,15.61,15.61,0,0,0-4.37,11.37,14.92,14.92,0,0,0,4.47,11.2,15.81,15.81,0,0,0,11.48,4.34,15.55,15.55,0,0,0,11.41-4.4,16.24,16.24,0,0,0-.19-22.51A16.06,16.06,0,0,0,144.27,382.73Z"/>
<path className="cls-1" d="M180,446.47V374.31h16.56q12.7,0,19.79,6.34t7.08,17.71q0,10.75-6.66,17.67a22.62,22.62,0,0,1-17,6.91,29.79,29.79,0,0,1-10.11-2v25.56Zm16.35-63.33h-6.68v29.41a19.69,19.69,0,0,0,9.12,2.23,14.06,14.06,0,0,0,10.86-4.62,17,17,0,0,0,4.22-11.9,16.72,16.72,0,0,0-2-8.26,12.12,12.12,0,0,0-5.44-5.22Q203,383.15,196.35,383.14Z"/>
<path className="cls-1" d="M264.68,349.54h10.39v63.11h32.67v9.45H264.68Z"/>
<path className="cls-1" d="M338.39,374.21q10.91,0,18.13,7a23.68,23.68,0,0,1,7.21,17.69,22.47,22.47,0,0,1-7.32,17.16q-7.32,6.84-18.44,6.84A24.93,24.93,0,0,1,320,416a24.48,24.48,0,0,1,.08-34.8Q327.37,374.21,338.39,374.21Zm-.52,8.52a14.93,14.93,0,0,0-11.17,4.47,15.61,15.61,0,0,0-4.37,11.37,14.92,14.92,0,0,0,4.47,11.2,15.81,15.81,0,0,0,11.48,4.34,15.55,15.55,0,0,0,11.41-4.4,16.24,16.24,0,0,0-.19-22.51A16.06,16.06,0,0,0,337.87,382.73Z"/>
<path className="cls-1" d="M371.89,418.68V408.49a43.21,43.21,0,0,0,8.19,4.55,19,19,0,0,0,7,1.74,8.78,8.78,0,0,0,5.08-1.46,4.15,4.15,0,0,0,2.13-3.47,4.68,4.68,0,0,0-1.37-3.46,31.83,31.83,0,0,0-5.95-4q-9.13-5.1-12-8.71a12.43,12.43,0,0,1-2.83-7.86,11,11,0,0,1,4.28-9q4.29-3.48,11-3.48a30.45,30.45,0,0,1,14.39,3.95v9.35q-8.42-5.1-13.77-5.09a7.67,7.67,0,0,0-4.44,1.17,3.61,3.61,0,0,0-1.69,3.09,4.47,4.47,0,0,0,1.53,3.18,26.33,26.33,0,0,0,5.38,3.63l3.38,1.92q11.94,6.75,11.95,15a11.91,11.91,0,0,1-4.6,9.63q-4.59,3.76-11.82,3.77a29,29,0,0,1-7.58-.91A52.05,52.05,0,0,1,371.89,418.68Z"/>
<path className="cls-1" d="M412.83,418.68V408.49A42.83,42.83,0,0,0,421,413a19,19,0,0,0,7,1.74,8.81,8.81,0,0,0,5.09-1.46,4.15,4.15,0,0,0,2.13-3.47,4.69,4.69,0,0,0-1.38-3.46,31.83,31.83,0,0,0-5.95-4q-9.13-5.1-12-8.71a12.43,12.43,0,0,1-2.83-7.86,11,11,0,0,1,4.28-9q4.29-3.48,11-3.48a30.42,30.42,0,0,1,14.39,3.95v9.35q-8.42-5.1-13.76-5.09a7.68,7.68,0,0,0-4.45,1.17,3.6,3.6,0,0,0-1.68,3.09,4.46,4.46,0,0,0,1.52,3.18,26.93,26.93,0,0,0,5.38,3.63l3.38,1.92q11.94,6.75,11.95,15a11.89,11.89,0,0,1-4.6,9.63q-4.59,3.76-11.82,3.77a29.06,29.06,0,0,1-7.58-.91A51.64,51.64,0,0,1,412.83,418.68Z"/>
<path className="cls-1" d="M467.48,349.43h60.61v9.25H502.8V422.1H492.36V358.68H467.48Z"/>
<path className="cls-1" d="M553.34,393.38v20.31c0,1.63.55,2.44,1.66,2.44s2.93-.85,5.35-2.54v5.76a31.2,31.2,0,0,1-5.17,2.83,11,11,0,0,1-4.07.76c-4.05,0-6.44-1.6-7.17-4.78a20.63,20.63,0,0,1-12.83,4.67,11.3,11.3,0,0,1-8.31-3.3,11.18,11.18,0,0,1-3.33-8.28,11.65,11.65,0,0,1,3.25-8.08,21.86,21.86,0,0,1,9.22-5.63l12.1-4.16v-2.55q0-8.62-8.62-8.62-7.74,0-15.07,8V379.87q5.5-6.5,15.85-6.49,7.74,0,12.41,4.05a12.74,12.74,0,0,1,2.81,3.46A12.29,12.29,0,0,1,553,385.2,62.6,62.6,0,0,1,553.34,393.38ZM544,412.65V398.47l-6.34,2.44a21.42,21.42,0,0,0-6.83,3.87,7,7,0,0,0-.1,9.69,6.71,6.71,0,0,0,4.91,1.87A11.88,11.88,0,0,0,544,412.65Z"/>
<path className="cls-1" d="M568.72,349.54h9.45V422.1h-9.45Zm31.11,24.77h11.22l-20.39,23.38,24.55,24.41H602.53l-23.94-24.38Z"/>
<path className="cls-1" d="M657.23,399H623.62q.38,6.85,4.6,10.91A15.17,15.17,0,0,0,639.16,414a28.43,28.43,0,0,0,17.24-5.82v9.25a31.47,31.47,0,0,1-8.65,4.15,35.8,35.8,0,0,1-10,1.25q-7.89,0-12.78-3.27a23.88,23.88,0,0,1-7.82-8.81,27,27,0,0,1-2.93-12.8q0-10.9,6.18-17.74t16-6.83q9.51,0,15.17,6.65t5.66,17.81Zm-33.4-5.66h24q-.36-5.67-3.37-8.73a10.88,10.88,0,0,0-8.11-3.06,11.65,11.65,0,0,0-8.33,3.06A15.15,15.15,0,0,0,623.83,393.38Z"/>
<path className="cls-1" d="M697.75,422.1V349.43h23q10.4,0,16.58,5.61t6.17,15.06a20.2,20.2,0,0,1-3.16,11.23,18.19,18.19,0,0,1-8.68,7.09q-5.5,2.2-15.79,2.2h-7.69V422.1ZM719,358.68H708.19v22.7h11.38q6.33,0,9.76-3t3.43-8.54Q732.76,358.67,719,358.68Z"/>
<path className="cls-1" d="M761.38,374.31v11l.52-.83q6.86-11.06,13.72-11.06c3.56,0,7.29,1.8,11.16,5.4l-5,8.31q-4.93-4.66-9.14-4.67-4.57,0-7.93,4.36a16.52,16.52,0,0,0-3.35,10.34v25h-9.5V374.31Z"/>
<path className="cls-1" d="M813.59,374.21q10.9,0,18.13,7a23.69,23.69,0,0,1,7.22,17.69,22.44,22.44,0,0,1-7.33,17.16q-7.32,6.84-18.44,6.84a24.93,24.93,0,0,1-18-6.94,24.48,24.48,0,0,1,.08-34.8Q802.57,374.21,813.59,374.21Zm-.52,8.52a14.93,14.93,0,0,0-11.17,4.47,15.6,15.6,0,0,0-4.36,11.37,15,15,0,0,0,4.46,11.2,15.82,15.82,0,0,0,11.49,4.34,15.54,15.54,0,0,0,11.4-4.4,16.25,16.25,0,0,0-.18-22.51A16.1,16.1,0,0,0,813.07,382.73Z"/>
<path className="cls-1" d="M850.52,374.31v-2.85q0-11.54,4.91-17.2a15.07,15.07,0,0,1,11.77-5.66,31.49,31.49,0,0,1,7,.94v9.24a12.9,12.9,0,0,0-4.37-.83q-5.55,0-7.71,3T860,371.46v2.85h8.51v8.52H860V422.1h-9.46V382.83h-6.86v-8.52Z"/>
<path className="cls-1" d="M880.91,354.21a5.56,5.56,0,0,1,4,1.61,5.31,5.31,0,0,1,1.66,3.95,5.67,5.67,0,0,1-5.66,5.61,5.23,5.23,0,0,1-3.85-1.69,5.44,5.44,0,0,1-1.66-3.92,5.37,5.37,0,0,1,1.66-3.87A5.23,5.23,0,0,1,880.91,354.21Zm-4.68,20.1h9.46V422.1h-9.46Z"/>
<path className="cls-1" d="M892.65,381.9l17.77-17.45v9.86h15.11v8.52H910.42v23.39q0,8.19,6.8,8.19A21.06,21.06,0,0,0,928,411v8.83a23.78,23.78,0,0,1-11.89,3.07,15.78,15.78,0,0,1-10.81-3.8,10.83,10.83,0,0,1-2.23-2.57,13.66,13.66,0,0,1-1.48-3.74,41.74,41.74,0,0,1-.6-8.8v-21.2h-8.31Z"/>
<path className="cls-2" d="M161.07,163.43V217h62.52a58.14,58.14,0,0,1-20.28,25.71,68.51,68.51,0,0,1-18.36,9A78.69,78.69,0,0,1,161.07,255a69.47,69.47,0,0,1-28.62-6A73.07,73.07,0,0,1,109,232.23a80.31,80.31,0,0,1-15.91-25.48,88.11,88.11,0,0,1-5.74-32.14,88.23,88.23,0,0,1,5.74-32.22A80.31,80.31,0,0,1,109,116.91a71.49,71.49,0,0,1,23.42-16.68,68.27,68.27,0,0,1,28.62-6,69.55,69.55,0,0,1,22.58,3.37,61.05,61.05,0,0,1,17.6,9.1,59.14,59.14,0,0,1,12.55,12.94,69.88,69.88,0,0,1,5.81,10.33h68.8a134.22,134.22,0,0,0-43.62-68.57,129.53,129.53,0,0,0-83.72-29.77,134.53,134.53,0,0,0-53.87,11,136.72,136.72,0,0,0-44,30.38,146.93,146.93,0,0,0-29.69,45.6,144.31,144.31,0,0,0-10.94,56.1A143.65,143.65,0,0,0,33.5,230.85a147.55,147.55,0,0,0,29.69,45.38,136.88,136.88,0,0,0,44,30.38,134.52,134.52,0,0,0,53.87,10.94A115.9,115.9,0,0,0,194.59,313a146.82,146.82,0,0,0,25-9.8,115,115,0,0,0,21.89-14.76l.53,26.78h55.25V163.43Z"/>
<path className="cls-2" d="M503.06,181.88A85.54,85.54,0,0,0,499,170.32a108.51,108.51,0,0,0-22.57-34,104.56,104.56,0,0,0-33.29-22.73,105.1,105.1,0,0,0-81.73,0,104.56,104.56,0,0,0-33.29,22.73,108.51,108.51,0,0,0-22.57,34,108.92,108.92,0,0,0,22.57,118.39,103.79,103.79,0,0,0,33.29,22.8,105.22,105.22,0,0,0,81.73,0,103.79,103.79,0,0,0,33.29-22.8,107,107,0,0,0,22.57-34,85.54,85.54,0,0,0,4.06-11.56,113.64,113.64,0,0,0,0-61.29Zm-69,68.18a43.25,43.25,0,0,1-63.44,0c-8.64-9.18-13-21.73-13-37.5s4.37-28.31,13-37.57a41.82,41.82,0,0,1,31.68-13.85c12.56,0,23.12,4.67,31.76,13.85S447,196.8,447,212.56,442.6,240.88,434,250.06Z"/>
<path className="cls-2" d="M739.67,169a136.83,136.83,0,0,0-10.79-49.43A141.72,141.72,0,0,0,698.73,75,140.09,140.09,0,0,0,654,44.82,136.91,136.91,0,0,0,599.1,33.88h-96V315.26h96A136.91,136.91,0,0,0,654,304.32a140.09,140.09,0,0,0,44.69-30.15,141.83,141.83,0,0,0,30.15-44.62c.92-2.06,1.76-4.21,2.53-6.35a140.24,140.24,0,0,0,8.41-48.59C739.82,172.77,739.82,170.86,739.67,169Zm-70.4,36.81a73.58,73.58,0,0,1-16.07,24.56A76.53,76.53,0,0,1,629,246.69a73.34,73.34,0,0,1-29.92,6.05H567.87V96.4H599.1a73.5,73.5,0,0,1,29.92,6,76.7,76.7,0,0,1,24.18,16.3,73.15,73.15,0,0,1,16.07,24.8,83.81,83.81,0,0,1,5.81,31.07A82.89,82.89,0,0,1,669.27,205.83Z"/>
<path className="cls-2" d="M932.59,170.32a108.51,108.51,0,0,0-22.57-34,104.56,104.56,0,0,0-33.29-22.73,105.1,105.1,0,0,0-81.73,0,105.45,105.45,0,0,0-33.29,22.73,109.18,109.18,0,0,0-22,32.67c-.15.38-.38.84-.53,1.3a106.66,106.66,0,0,0-8.27,42.24,96.21,96.21,0,0,0,.54,10.64,100.6,100.6,0,0,0,7.73,31.53,106.81,106.81,0,0,0,22.57,34A104.67,104.67,0,0,0,795,311.51a105.22,105.22,0,0,0,81.73,0A103.79,103.79,0,0,0,910,288.71a107.29,107.29,0,0,0,30.83-76.15A106.66,106.66,0,0,0,932.59,170.32Zm-65,79.74a43.25,43.25,0,0,1-63.44,0c-8.65-9.18-13-21.73-13-37.5s4.36-28.31,13-37.57a41.82,41.82,0,0,1,31.68-13.85c12.55,0,23.11,4.67,31.76,13.85s12.93,21.81,12.93,37.57S876.19,240.88,867.62,250.06Z"/></svg>
export default logo;