class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("token"));
    return user;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("token"));
    return user;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem("token", token);
  }

  getUser() {
    return JSON.parse(localStorage.getItem("token"));
  }

  setUser(user) {
    localStorage.setItem("token", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("token");
  }
}

const service = new TokenService();
export default service;
