import React, { Fragment } from 'react';
import { Table, Row, Col } from 'reactstrap';
import TableNodata from 'CommonElements/Table/TableNodata';

const OpenPositions = ({ positions = {} }) => {
  const {
    data = []
  } = positions;
  if (!positions || !positions.data) return ('');
  return (
    <Fragment>
        
        <Row>
            <Col md={12} className="mt-5 ">
                <h5>Open Positions</h5>
            </Col>
        </Row>
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">Login</th>
                        <th scope="col">Symbol</th>
                        <th scope="col">Position</th>
                        <th scope="col">Time</th>
                        <th scope="col">Deal Type</th>
                        <th scope="col">Lots</th>
                        <th scope="col">Price</th>
                        <th scope="col">Price SL</th>
                        <th scope="col">Price TP</th>
                        <th scope="col">Price Position</th>
                        <th scope="col">Swap</th>
                        <th scope="col">Profit</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, id) =>
                            <tr key={id}>
                                <td >{item.Login}</td>
                                <td>{item.Symbol}</td>
                                <td>{item.Position}</td>
                                <td>{item.Time}</td>
                                <td>{item.Action}</td>
                                <td>{(item.Volume/parseInt(item.ContractSize))*10}</td>
                                <td>{item.PriceOpen}</td>
                                <td>{item['PriceSL']}</td>
                                <td>{item['PriceTP']}</td>
                                <td>{item['PricePosition']}</td>
                                <td>{item.Swap}</td>
                                <td>{item.Profit}</td>
                            </tr>
                        )
                    }
                    
                </tbody>
                {
                        positions.data && positions.data.length === 0 && 
                        <TableNodata title="Open Positions" />
                        
                    }
            </Table>
        </div>
    </Fragment>
  );
};
export default OpenPositions;